import React, { useState, useEffect, useRef } from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { makeNetworkData } from "../../utils";
import { cluster, hierarchy } from "d3-hierarchy";
import { csvParse } from "d3-dsv";
import * as d3 from "d3";
import { DateTime } from "luxon";
import CambodiaMap from './RegionalMapChart';

// import { path } from 'd3-path'
// import { elementBackgroundColor, lineGrey } from '../../Elements'

const DailyCasesChart = (props) => {
  const { highlight, size, color, numDays } = props;
  const { width } = size;
  const [rawdata, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [classifieddata, classifiedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [province, setProvince] = useState("");
  const svgRef = useRef();
  const provinces = [
    { name: "Banteay Meanchey" },
    { name: "Battambang" },
    { name: "Kampong Cham" },
    { name: "Kampong Chhnang" },
    { name: "Kampong Speu" },
    { name: "Kampong Thom" },
    { name: "Kampot" },
    { name: "Kandal" },
    { name: "Koh Kong" },
    { name: "Kratie" },
    { name: "Mondulkiri" },
    { name: "Phnom Penh" },
    { name: "Preah Vihear" },
    { name: "Prey Veng" },
    { name: "Pursat" },
    { name: "Ratanak Kiri" },
    { name: "Siem Reap" },
    { name: "Preah Sihanouk" },
    { name: "Stung Treng" },
    { name: "Svay Rieng" },
    { name: "Takeo" },
    { name: "Oddar Meanchey" },
    { name: "Kep" },
    { name: "Pailin" },
    { name: "Tboung Khmum" },
  ];
  const [selectedprovince, setSelectedProvince] = useState("Banteay Meanchey");

  const leftTextWidth = 10;
  const rightTextWidth = 10;
  const lineHeight = size.height;
  const barPadding = 1;
  const leftAxisPadding = 50;
  const bottomAxisPadding = 20;
  const adjustedWidth =
    size.width - (leftTextWidth + rightTextWidth + leftAxisPadding);
  const containerWidth = size.width - (leftTextWidth + rightTextWidth);
  const adjustedHeight =
    lineHeight - (leftTextWidth + rightTextWidth + bottomAxisPadding);
  const containerHeight = lineHeight;

  const handleProvince = (name) => {
    setProvince(name);
  };

  useEffect(() => {
    // d3.csv("https://docs.google.com/spreadsheets/d/e/2PACX-1vTeklVLDlAUqSi8zwg9XqFTIljHUFJkfcGygwe8WnwG32Hb4h6OmpVZBwOzLubA3hbiSx1sYXTPP_i7/pub?gid=707339072&single=true&output=csv").then((d) => {
    // d3.csv("https://data.opendevelopmentcambodia.net/en/datastore/dump/a3fdfb1b-4d6e-4653-a499-0acfe972a0a5?bom=True").then((d) => {
    d3.csv(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQkLytTn5lDnsOht865NZRmypUBQKZDN5Sf9RLVs11O9f1AbmF_ZhA53sErE2tG637HtOABPoaEX1Fn/pub?gid=0&single=true&output=csv"
    ).then((d) => {
      setRawData(d);
      // setData(d);
      setLoading(false);
    });
    return () => undefined;
  }, []);

  useEffect(() => {
    let host = [];
    !!rawdata.length &&
      rawdata.map((data) => {
        if (
          data.province_en.replace(/\s/g, "") ===
          selectedprovince.replace(/\s/g, "")
        ) {
          host.push(data);
        }
      });
    setData(host);
  }, [selectedprovince, rawdata]);


  useEffect(() => {
    let host = [];
    !!rawdata.length &&
      rawdata.map((data) => {
        if (
          data.province_en.replace(/\s/g, "") ===
          province.replace(/\s/g, "")
        ) {
          host.push(data);
        }
      });
    setData(host);
  }, [province, rawdata]);

  useEffect(() => {
    setSelectedProvince(province);
  }, [province])

  console.log("Unclassified data", rawdata);
  console.log("Data", data);

  const handleProvinceChange = (e) => {
    setSelectedProvince(e.target.value);
  };

  const convertToDate = (dateString) => {
    const dateArray = dateString.split(" ")[0];
    return DateTime.fromISO(dateArray);
  };

  const daysDiff = (date1, date2) => {
    // const difference = date1.getTime() - date2.getTime();
    // return Math.abs( Math.ceil(difference / (1000 * 3600 * 24)) );
    return date2.diff(date1, ["days"]).as("days");
  };

  const maxDate =
    data.length > 0
      ? convertToDate(data[data.length - 1]["created_at"])
      : DateTime.now();

  // const dateFormat = d3.timeParse("%d/%m/%Y");
  const nestedData = d3
    .nest()
    .key(function (d) {
      return d["created_at"];
    })
    // .key(function(d) { return d["region"]; })
    .rollup(function (leaves) {
      const leafDate = convertToDate(leaves[0]["created_at"]);
      return {
        date: leafDate.toJSDate(),
        luxonDate: leafDate,
        datePretty: leafDate.toISODate(),
        daysFromMax: daysDiff(leafDate, maxDate),
        totalCases: +d3.sum(leaves, function (d) {
          return parseInt(d["infected"]);
        }),
      };
    })
    .entries(data);

  // console.log(nestedData);

  const dateFill = (dataToFill) => {
    const placeHolderArray = [...Array(numDays).keys()].map((day) => {
      const todaysDate = maxDate.plus({ days: -day });
      return {
        date: todaysDate.toJSDate(),
        luxonDate: todaysDate,
        datePretty: todaysDate.toISODate(),
        daysFromMax: day,
        totalCases: 0,
      };
    });

    return placeHolderArray.map((day) => {
      const realDataForDayArray =
        dataToFill && dataToFill.length > 0
          ? dataToFill.filter((realDataDay) => {
            return realDataDay.value.daysFromMax === day.daysFromMax;
          })
          : [];

      if (realDataForDayArray.length > 0) {
        return {
          ...day,
          ...realDataForDayArray[0].value,
        };
      } else {
        return day;
      }
    });
  };

  const latestNestedData = dateFill(
    nestedData.filter((row) => row.value.daysFromMax <= numDays)
  );

  const onMouseEnter = (item) => {
    // setTooltip({item:item});
    setHoverCountry({ item: item, isToolTip: true });
  };
  const onMouseLeave = (item) => {
    // setTooltip(false);
    setHoverCountry({ item: item, isToolTip: false });
  };

  const Tooltip = ({ country }) => {
    // console.log(mouseX,mouseY);
    // const dataToMap = data[country.properties.ISO_A3];
    const tooltipWidth = 100;
    const tooltipHeight = tooltipWidth;
    // console.log(mouseX,mouseY,size.x,size.y);
    // console.log(country)

    return (
      <foreignObject
        style={{
          pointerEvents: "none",
        }}
        x={
          mouseX + tooltipWidth < size.x + width - tooltipWidth
            ? mouseX + 20 - size.x
            : mouseX - 10 - size.x - tooltipWidth
        }
        // y={ mouseY + 10 + 0.6*tooltipHeight < size.y + height
        //   ? mouseY - size.y
        //   : mouseY - size.y - (0.9*tooltipHeight)
        // }
        y={0}
        width={tooltipWidth}
        height={tooltipHeight}
      >
        <div
          className="tooltip"
          style={{
            backgroundColor: "rgb(80,128,94)",
            border: "1px solid",
            padding: "5px 5px 5px 5px",
            textAlign: "left",
            borderColor: "#fff",
          }}
        >
          {country.item && country.item.index ? (
            <div>
              {country.item.name}
              <br />
              Rank: #{country.item.rank}
            </div>
          ) : null}
        </div>
      </foreignObject>
    );
  };

  const max = d3.max(latestNestedData.map((d) => d.totalCases));

  const formatXTick = (d) => {
    console.log(d);
    return DateTime.fromJSDate(d).toLocaleString({
      month: "long",
      day: "numeric",
    });
  };

  const scaleX = d3
    .scaleTime()
    .domain(d3.extent(latestNestedData.map((d) => d.date)))
    .range([0, adjustedWidth]);
  const scaleY = d3
    .scaleLinear()
    .domain([0, max])
    .range([adjustedHeight, 0]);
  const yAxis = d3
    .axisLeft()
    .ticks(5)
    .scale(scaleY)
    .tickSize(-adjustedWidth);
  const xAxis = d3
    .axisBottom()
    .scale(scaleX)
    .ticks(4)
    .tickFormat(formatXTick);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg
      .select(".y-axis")
      .style("transform", "translateX(" + leftAxisPadding + "px)")
      .call(yAxis);
    svg
      .select(".x-axis")
      .style(
        "transform",
        "translateY(" +
        adjustedHeight +
        "px) translateX(" +
        leftAxisPadding +
        "px)"
      )
      .call(xAxis);
    svg
      .select(".lineMarkerG")
      .style("transform", "translateX(" + leftAxisPadding + "px)");

    svg
      .selectAll(".y-axis .tick line")
      .attr("stroke-opacity", 0.5)
      .attr("stroke-dasharray", "2,2");
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Select
            onChange={handleProvinceChange}
            value={selectedprovince}
            // className={styles.lgSwitch}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
                fontSize: "1rem",
                fontWeight: "bold",
                background: "transparent",
              },
              getContentAnchorEl: null,
            }}
          >
            {provinces.map((item) => (
              <MenuItem
                key={item.name}
                value={item.name}
              // className={styles.lgSwitch_Options}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CambodiaMap callback={handleProvince} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", padding: '1rem' }}>
          <svg
            className="lineMarker"
            width={containerWidth}
            height={lineHeight}
            ref={svgRef}
          >
            <g className="y-axis" />
            <g className="x-axis" />
            <g className="lineMarkerG" height={lineHeight}>
              {latestNestedData.map((m, i) => {
                return (
                  <rect
                    height={adjustedHeight - scaleY(+m.totalCases)}
                    width={adjustedWidth / latestNestedData.length - barPadding}
                    x={scaleX(m.date)}
                    y={scaleY(+m.totalCases)}
                    d={m.totalCases}
                    // fill={
                    //   highlightIndices.includes(m.index) || (hoverCountry.item && hoverCountry.item.index === m.index  && hoverCountry.isToolTip)
                    //   ? color.highlight : color.primary
                    // }
                    fill={color.primary}
                  // onMouseEnter={() => onMouseEnter(m)}
                  // onMouseLeave={() => onMouseLeave(m)}
                  />
                );
              })}
            </g>
          </svg>
          {/* <div style={{ display: "flex" }}>
            <div
              style={{
                paddingLeft: leftTextWidth + "px",
                paddingRight: rightTextWidth + "px",
              }}
            >
              <svg
                className="lineMarker"
                width={containerWidth}
                height={lineHeight}
                ref={svgRef}
              >
                <g className="y-axis" />
                <g className="x-axis" />
                <g className="lineMarkerG" height={lineHeight}>
                  {latestNestedData.map((m, i) => {
                    return (
                      <rect
                        height={adjustedHeight - scaleY(+m.totalCases)}
                        width={
                          adjustedWidth / latestNestedData.length - barPadding
                        }
                        x={scaleX(m.date)}
                        y={scaleY(+m.totalCases)}
                        d={m.totalCases}
                        // fill={
                        //   highlightIndices.includes(m.index) || (hoverCountry.item && hoverCountry.item.index === m.index  && hoverCountry.isToolTip)
                        //   ? color.highlight : color.primary
                        // }
                        fill={color.primary}
                        // onMouseEnter={() => onMouseEnter(m)}
                        // onMouseLeave={() => onMouseLeave(m)}
                      />
                    );
                  })}
                </g>
              </svg>
            </div>
          </div> */}
        </Grid>
      </Grid>
    </>
  );
};

export default DailyCasesChart;
