import React, { useContext, useRef, useState } from 'react'
import { Grid, Box, Dialog, Typography, IconButton } from '@material-ui/core'
import TextAreaAutosize from '@material-ui/core/TextareaAutosize'
import { Image as ImageIcon, FileCopy as FileCopyIcon, Reply as ReplyIcon, Description as DescriptionIcon } from '@material-ui/icons'
import { CSVLink } from 'react-csv'
import useDimensions from "react-use-dimensions"
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga'
import { Text, LanguageContext } from '../../LangProvider'
import { DownShareButton, PNGButton, DialogTitle, DialogContent, currenturl, translateNumber, modalboxcopystyle, textareacontainerstyle_iconbutton, textareacontainerstyle, textareacontainerstyle_iconbutton2, Event } from '../../Elements'
import NetworkDiagram from '../../Pages/TableWrapper/NetworkDiagram'


const ComponentNetwork = ({ allData, caseID, filteredData }) => {
    ReactGA.initialize('UA-159319358-2')
    const languageContext = useContext(LanguageContext)
    const [Open, setOpen] = useState(false)
    const [netWorkDiagramRef, networkDiagramSize] = useDimensions();
    const [svgNetworkRef, setSVGNetworkRef] = useState(null)
    const textAreaRefIframe = useRef(null)
    const textAreaRefLink = useRef(null)
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })

    const copyToClipboardIframe = (e) => {
        textAreaRefIframe.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Iframe Link Button to Embedded page", `<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casenetwork/?lang=${languageContext.language.id}&caseID=${caseID}"></iframe>`, "Components/ComponentDiagram")
    }

    const copyToClipboardLink = (e) => {
        textAreaRefLink.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Link Button to Embedded page", `${currenturl}/casetracing/casenetwork/?lang=${languageContext.language.id}&caseID=${caseID}`, "Components/ComponentDiagram")
    }

    const serialize = (svg) => {

        const xmlns = "http://www.w3.org/2000/xmlns/";
        const xlinkns = "http://www.w3.org/1999/xlink";
        const svgns = "http://www.w3.org/2000/svg";

        svg = svg.cloneNode(true);
        const fragment = window.location.href + "#";
        const walker = document.createTreeWalker(svg, NodeFilter.SHOW_ELEMENT, null, false);
        while (walker.nextNode()) {
            for (const attr of walker.currentNode.attributes) {
                if (attr.value.includes(fragment)) {
                    attr.value = attr.value.replace(fragment, "#");
                }
            }
        }
        svg.setAttributeNS(xmlns, "xmlns", svgns);
        svg.setAttributeNS(xmlns, "xmlns:xlink", xlinkns);
        const serializer = new window.XMLSerializer();
        const string = serializer.serializeToString(svg);
        return new Blob([string], { type: "image/svg+xml" });
    }



    const saveSVGNetwork = () => {
        if (svgNetworkRef) {
            let csvURL = window.URL.createObjectURL(serialize(svgNetworkRef));
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', "Case_" + caseID + "_NetworkDiagram.svg");
            tempLink.click();
        }
        Event("Download Network Diagram as SVG", `Case_${caseID}_NetworkDiagram.svg`, "Components/ComponentDiagram")
    }

    const handleOpen = () => {
        setOpen(true)
        Event("Download Share Button", `Click download & share button to open modal box for Network Diagram Component`, "Components/ComponentDiagram")
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (

        <>
            <Grid container spacing={2} style={{ background: '#fff', marginTop: '3rem' }}>
                {languageContext.language.id === 'en' ? (<>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold', padding: '0.2em' }}>
                            Network Diagram between patient <Text tid="patientcase" /> {caseID} and other patients
                        </Typography>
                    </Grid>
                </>) : (<>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center', fontWeight: 'bold', padding: '0.2em' }}>
                            <Text tid="patientcase" /> {translateNumber(parseInt(caseID))} နှင့် အခြားလူနာများ ဆက်စပ်မှု
                        </Typography>
                    </Grid>
                </>)}
            </Grid>
            <Box display="flex" justifyContent="center" style={{ background: '#ffffff' }} ref={netWorkDiagramRef} width="100%" height={700}>
                <NetworkDiagram
                    allData={allData}
                    filteredData={filteredData}
                    currentID={caseID}
                    size={networkDiagramSize}
                    getSvgWrapper={setSVGNetworkRef}
                />
            </Box>
            <Box display="flex" justifyContent="center" p={1} >
                <DownShareButton variant="contained" color="primary" size="large" startIcon={<ReplyIcon />} onClick={handleOpen}>
                    <Box p={1}>
                        <Text tid="downloadandshare" />
                    </Box>
                </DownShareButton>
            </Box>
            <Box style={{ height: '10vh' }}></Box>
            <Dialog fullWidth={true} maxWidth={"lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={Open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Box display="flex" flexDirection="row">
                        <span className="dialogtitle"><Text tid="downloadandshare" /> - <Text tid="networkdiagram" /></span>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {/* <Box display="flex" justifyContent="center" m={4}>
                        <Box p={1} m={1}>
                            <PNGButton variant="contained" color="primary" size="large" startIcon={<ImageIcon />}>
                                <Box p={1} style={{ width: '10rem' }}><Text tid="btnimage" /></Box>
                            </PNGButton>
                        </Box>
                    </Box> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-end" : "center"}>
                                <PNGButton variant="contained" color="primary" size="large" startIcon={<ImageIcon />} onClick={saveSVGNetwork}>
                                    <Box p={1} style={{ width: '10rem' }}><Text tid="btnimage" /></Box>
                                </PNGButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-start" : "center"}>
                                <CSVLink data={filteredData} filename={`covid19_myanmar_case_${caseID}_network.csv`}>
                                    <PNGButton variant="contained" color="primary" size="large" startIcon={<DescriptionIcon />} id="csvdownloader">
                                        <Box p={1} style={{ width: '10rem' }}><Text tid="btndata" /></Box>
                                    </PNGButton>
                                </CSVLink>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box style={{ height: '2vh' }}>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        <Text tid="shareembeddedphrase" />
                    </Typography>
                    <Typography >
                        <Text tid="copyandpastephrase" />
                    </Typography>
                    <Grid container>
                        <Grid item md={12} xs={12} style={textareacontainerstyle}>
                            <TextAreaAutosize
                                ref={textAreaRefIframe}
                                id="filled-multiline-static"
                                rowsMin={3}
                                cols={20}
                                value={`<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casenetwork/?lang=${languageContext.language.id}&caseID=${caseID}"></iframe>`}
                                rows="4"
                                variant="filled"
                                className="iframearea"
                            />
                            <Box display="flex" justifyContent="flex-end" style={modalboxcopystyle}>
                                <IconButton className="standard-button" onClick={copyToClipboardIframe} style={textareacontainerstyle_iconbutton}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box style={{ height: '2vh' }}>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        <Text tid="sharesocialphrase" />
                    </Typography>
                    <Typography >
                        <Text tid="copylinkphrase" />
                    </Typography>
                    <Grid container m={1}>
                        <Grid item xs={12} md={12} style={textareacontainerstyle}>
                            <Box display="flex" flexDirection="row">
                                <TextAreaAutosize
                                    ref={textAreaRefLink}
                                    id="filled-multiline-static"
                                    rowsMin={3}
                                    cols={20}
                                    value={`${currenturl}/casetracing/casenetwork/?lang=${languageContext.language.id}&caseID=${caseID}`}
                                    className="linkarea"
                                />
                                <IconButton className="standard-button" onClick={copyToClipboardLink} style={textareacontainerstyle_iconbutton2}>
                                    <FileCopyIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ComponentNetwork
