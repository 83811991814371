import './SVGCaseTimeline.module.scss'
import React, { useContext, useState, useEffect } from 'react'
import Flexbox from "react-svg-flexbox";
import { useMediaQuery } from 'react-responsive'
import 'moment/locale/my'
import Moment from 'react-moment'
import moment from 'moment'
import { LanguageContext } from '../../LangProvider';
import { elementBackgroundColor, lineGrey } from '../../Elements'

export default (props) => {
  const { segments, mapDetail, size, getSvgWrapper } = props;
  const languageContext = useContext(LanguageContext)
  const [source, setSource] = useState('https://www.mohs.gov.mm/Main/content/page/covid-19-contact')
  const width = size.width;
  const yStart = 50;
  const xStart = 80;
  moment.locale('my')
  useEffect(() => {
    segments && segments.map((segment) => {
      if (segment.locationData.source.length > 5) {
        return setSource(segment.locationData.source)
      }
      return null
    })
  }, [segments])

  const dateValueFormatter = (val) => {
    return new Date(val)
  }

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })
  return (
    <>
      {
        isDesktopOrLaptop ? (
          <div className={'timelineSVGWrapper'}>
            <svg style={{ width: width, height: ((segments.length) * 14.5) + "em" }} ref={ref => getSvgWrapper(ref)}>
              <line x1={xStart} y1={yStart} x2={xStart} y2={((segments.length) * 20.5) + "em"} style={{ stroke: lineGrey, strokeWidth: "2" }} />
              <Flexbox
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                  textAlign: "center"
                }}
                y={yStart}
                x={xStart}
              >
                {
                  segments.map((segment, key) => {
                    return <g key={"locationDetail" + key}>
                      <circle r={20} cy={"-5"} style={{ fill: elementBackgroundColor }} ></circle>
                      <text key={"txt" + key} style={{ textAnchor: "middle", fill: "#ffffff", fontWeight: "bold", fontSize: "1.2em" }}>{key + 1}</text>
                      {
                        segment.locationData.description && segment.locationData.timestamp && segment[mapDetail].locationEN ? (
                          <>
                            {
                              segment.locationData.description.length > 300 ? (
                                <>
                                  <foreignObject width={"70%"} height="320" x={25} y={-35}>
                                    <div xmlns="http://www.w3.org/1999/xhtml">
                                      <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                      {
                                        segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                      }
                                      <div>
                                        <div style={{ wordWrap: 'break-word' }}>
                                          <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </foreignObject>
                                </>
                              ) :
                                (
                                  <>
                                    <foreignObject width={"70%"} height="180" x={25} y={-35}>
                                      <div xmlns="http://www.w3.org/1999/xhtml">
                                        <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                        {
                                          segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                        }
                                        <div style={{ wordWrap: 'break-word' }}>
                                          <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                        </div>
                                      </div>
                                    </foreignObject>
                                  </>
                                )
                            }
                          </>) : (<>
                            {
                              !segment.locationData.description ? (<>
                                {!segment[mapDetail].locationEN ? (<>
                                  {!segment.locationData.timestamp ? null : (<>
                                    <foreignObject width={"70%"} height="28.8" x={25} y={-35}>
                                      <div xmlns="http://www.w3.org/1999/xhtml">
                                        {
                                          segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                        }
                                      </div>
                                    </foreignObject>
                                  </>)}
                                </>) : (<>
                                  <foreignObject width={"70%"} height="40" x={25} y={-35}>
                                    <div xmlns="http://www.w3.org/1999/xhtml">
                                      <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                      {
                                        segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                      }
                                    </div>
                                  </foreignObject>
                                </>)}
                              </>) : (<>
                                {
                                  segment.locationData.description.length > 300 ? (
                                    <>
                                      <foreignObject width={"70%"} height="240" x={25} y={-35}>
                                        <div xmlns="http://www.w3.org/1999/xhtml">
                                          <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                          {
                                            segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                          }
                                          <div>
                                            <div style={{ wordWrap: 'break-word' }}>
                                              <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </foreignObject>
                                    </>
                                  ) :
                                    (
                                      <>
                                        <foreignObject width={"70%"} height="96" x={25} y={-35}>
                                          <div xmlns="http://www.w3.org/1999/xhtml">
                                            <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                            {
                                              segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                            }
                                            <div style={{ wordWrap: 'break-word' }}>
                                              <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                            </div>
                                          </div>
                                        </foreignObject>
                                      </>
                                    )
                                }
                              </>)
                            } </>)}
                    </g>
                  })
                }
                <g>
                  <foreignObject width={"70%"} height="64" x={5} y={-35}>
                    <div xmlns="http://www.w3.org/1999/xhtml">
                      <span> <a href={source} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontSize: '0.9em' }} id="datasource">Data Source: {source}</a></span>
                    </div>
                  </foreignObject>
                </g>
              </Flexbox>
            </svg>

          </div>

        ) : (
            <div className={'timelineSVGWrapper'}>
              <svg style={{ width: width, height: ((segments.length) * 16.5) + "em" }} ref={ref => getSvgWrapper(ref)}>
                <line x1={xStart} y1={yStart} x2={xStart} y2={((segments.length) * 16.5) + "em"} style={{ stroke: lineGrey, strokeWidth: "2" }} />
                <Flexbox
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "100%",
                    textAlign: "center",
                  }}
                  y={yStart}
                  x={xStart}
                >
                  {
                    segments.map((segment, key) => {
                      return <g key={"locationDetail" + key}>
                        <circle r={20} cy={"-5"} style={{ fill: elementBackgroundColor }} ></circle>
                        <text key={"txt" + key} style={{ textAnchor: "middle", fill: "#ffffff", fontWeight: "bold", fontSize: "1.2em" }}>{key + 1}</text>
                        {
                          segment.locationData.description && segment.locationData.timestamp && segment[mapDetail].locationEN ? (
                            <>
                              {
                                segment.locationData.description.length > 300 ? (
                                  <>
                                    <foreignObject width={"70%"} height="448" x={25} y={-35}>
                                      <div xmlns="http://www.w3.org/1999/xhtml">
                                        <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                        {
                                          segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                        }
                                        <div>
                                          <div style={{ wordWrap: 'break-word' }}>
                                            <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </foreignObject>
                                  </>
                                ) :
                                  (
                                    <>
                                      <foreignObject width={"70%"} height="192" x={25} y={-35}>
                                        <div xmlns="http://www.w3.org/1999/xhtml">
                                          <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                          {
                                            segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                          }
                                          <div style={{ wordWrap: 'break-word' }}>
                                            <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                          </div>
                                        </div>
                                      </foreignObject>
                                    </>
                                  )
                              }
                            </>) : (<>
                              {
                                !segment.locationData.description ? (<>
                                  {!segment[mapDetail].locationEN ? (<>
                                    {!segment.locationData.timestamp ? null : (<>
                                      <foreignObject width={"70%"} height="35.2" x={25} y={-35}>
                                        <div xmlns="http://www.w3.org/1999/xhtml">
                                          {
                                            segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                          }
                                        </div>
                                      </foreignObject>
                                    </>)}
                                  </>) : (<>
                                    <foreignObject width={"70%"} height="44.8" x={25} y={-35}>
                                      <div xmlns="http://www.w3.org/1999/xhtml">
                                        <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                        {
                                          segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                        }
                                      </div>
                                    </foreignObject>
                                  </>)}
                                </>) : (<>
                                  {
                                    segment.locationData.description.length > 300 ? (
                                      <>
                                        <foreignObject width={"70%"} height="288" x={25} y={-35}>
                                          <div xmlns="http://www.w3.org/1999/xhtml">
                                            <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                            {
                                              segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                            }
                                            <div>
                                              <div style={{ wordWrap: 'break-word' }}>
                                                <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </foreignObject>
                                      </>
                                    ) :
                                      (
                                        <>
                                          <foreignObject width={"70%"} height="128" x={25} y={-35}>
                                            <div xmlns="http://www.w3.org/1999/xhtml">
                                              <h4>{languageContext.language.id === 'en' ? segment[mapDetail].locationEN : segment[mapDetail].locationMM}</h4>
                                              {
                                                segment.locationData.timestamp ? (<p className="timelinedate">{languageContext.language.id === 'en' ? (<>{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>) : (<>{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(segment.locationData.timestamp)}</Moment>}</>)}</p>) : null
                                              }
                                              <div style={{ wordWrap: 'break-word' }}>
                                                <span style={{ fontSize: '0.9em' }}>{segment.locationData.description}</span>
                                              </div>
                                            </div>
                                          </foreignObject>
                                        </>
                                      )
                                  }
                                </>)
                              } </>)}
                      </g>
                    })
                  }
                  <g>
                    <foreignObject width={"70%"} height="96" x={5} y={-35}>
                      <div xmlns="http://www.w3.org/1999/xhtml">
                        <span> <a href={source} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontSize: '0.9em' }} id="datasource">Data Source: {source}</a></span>
                      </div>
                    </foreignObject>
                  </g>
                </Flexbox>
              </svg>
            </div>
          )
      }
    </>
  )
}

