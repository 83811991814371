import React  from 'react';

export default function PageAbout() {

    return (
        <>
            <h1>About</h1>
        </>
    )

}