import React, { useContext, useEffect, useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import Select from 'react-select'
import ReactGA from 'react-ga'
import { translateNumber, selectstyle, Event, DisplayEmptyBox } from '../../Elements'
import useDimensions from "react-use-dimensions";
import { languageOptions } from '../../Languages'
import { Text, LanguageContext } from '../../LangProvider';
import NetworkDiagram from '../../Pages/TableWrapper/NetworkDiagram'


const ComponentNetworkDiagram = ({ allData, caseid, filteredData, lang }) => {
    ReactGA.initialize('UA-159319358-2')
    const languageContext = useContext(LanguageContext)
    const [caseID, setCaseID] = useState(caseid ? caseid : '01')
    const [selectedValue, setSelectedValue] = useState(caseid);
    // eslint-disable-next-line 
    const [svgTimelineRef, setSVGTimelineRef] = useState(null)
    const [netWorkDiagramRef, networkDiagramSize] = useDimensions();
    useEffect(() => {
        if (lang) {
            const selectedLanguage = languageOptions.find(item => item.id === lang)
            languageContext.setLanguage(selectedLanguage)
        }
    }, [lang, languageContext])

    let options = []
    allData.map(row => {
        let formatted = { value: row.confirmed_case_id, label: row.confirmed_case_id }
        return options.push(formatted)
    })

    const handleSelectChange = e => {
        setCaseID(e.value)
        setSelectedValue(e.value)
        Event("Select Button", `selected case ${e.value} using select Button`, "PageEmbeddedNetworkDiagram")
    }

    return (

        <>
            {
                caseID && caseID.length ? (
                    <>
                        {
                            filteredData ?
                                (
                                    <>
                                        {filteredData.length > 0 ? (
                                            <>
                                                <Grid container spacing={2} style={{ background: '#fff', marginTop: '5rem' }}>
                                                    {languageContext.language.id === 'en' ? (<>
                                                        <Grid item xs={12}>
                                                            <h1 style={{ textAlign: 'center', padding: '1rem' }}> Network Diagram between patient <Text tid="patientcase" /> {caseID} and other patients</h1>
                                                            <Box display="flex" justifyContent="center" p={1} bgcolor="background.paper" style={{ borderRadius: '0.2rem' }}>
                                                                <h3> <Text tid="patientcase" /> - {}  </h3>
                                                                <div style={{ width: '8rem' }}><Select
                                                                    placeholder={lang === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                                                    value={selectedValue}
                                                                    options={options}
                                                                    onChange={handleSelectChange}
                                                                    styles={selectstyle}
                                                                /></div>
                                                            </Box>
                                                        </Grid>
                                                    </>) : (<>
                                                        <Grid item xs={12}>
                                                            <h1 style={{ textAlign: 'center', padding: '1rem' }}><Text tid="patientcase" /> {caseID} နှင့် အခြားလူနာများ ဆက်စပ်မှု </h1>
                                                            <Box display="flex" justifyContent="center" p={1} bgcolor="background.paper" style={{ borderRadius: '0.2rem' }}>
                                                                <h3> <Text tid="patientcase" /> - {}  </h3>
                                                                <div style={{ width: '8rem' }}><Select
                                                                    placeholder={lang === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                                                    value={selectedValue}
                                                                    options={options}
                                                                    onChange={handleSelectChange}
                                                                    styles={selectstyle}
                                                                /></div>
                                                            </Box>
                                                        </Grid>
                                                    </>)}
                                                </Grid>
                                                <Box display="flex" justifyContent="center" style={{ background: '#ffffff' }} ref={netWorkDiagramRef} width="100%" height={700}>
                                                    <NetworkDiagram
                                                        allData={allData}
                                                        filteredData={filteredData}
                                                        currentID={caseID}
                                                        size={networkDiagramSize}
                                                        getSvgWrapper={setSVGTimelineRef}
                                                    />
                                                </Box>
                                            </>

                                        ) : (<>
                                            <DisplayEmptyBox />
                                        </>)}
                                    </>
                                ) :
                                (
                                    <>
                                    </>
                                )
                        }
                    </>
                ) :
                    (
                        <>
                        </>
                    )
            }
        </>
    )
}

export default ComponentNetworkDiagram