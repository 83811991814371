export default (routes, currentCase) => routes
  .map((d) => {
    // console.log(d);
    const currentCaseArray = d.pid.split("_")
    const caseID = currentCaseArray[1];
    const stopID = currentCaseArray[2];
    return { caseID, stopID, ...d }
  })
  .filter((d) => {
    return +d.caseID === +currentCase
  })
  .sort((a, b) => +a.stopID > b.stopID ? 1 : -1)
