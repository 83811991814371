import en from './en'
import mm from './mm'
import km from './km'

export const dictionaryList = {
    en,
    mm,
    km
};

export const languageOptions = [
    { id: 'en', text: 'English' },
    { id: 'mm', text: 'မြန်မာ' },
    { id: 'km', text: 'ប្រទេសកម្ពុជា' }
];