import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Box, Dialog, Typography, IconButton } from "@material-ui/core";
import TextAreaAutosize from "@material-ui/core/TextareaAutosize";
import useDimensions from "react-use-dimensions";
import { Text, LanguageContext } from "../../LangProvider";
import { useMediaQuery } from "react-responsive";
import {
  Image as ImageIcon,
  FileCopy as FileCopyIcon,
  Reply as ReplyIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";
import {
  PNGButton,
  currenturl,
  DialogContent,
  DialogTitle,
  modalboxcopystyle,
  textareacontainerstyle,
  textareacontainerstyle_iconbutton2,
  textareacontainerstyle_iconbutton,
  DownShareButton,
  Event,
} from "../../Elements";
import axios from "axios";
import Iframe from "react-iframe";
import styles from "./index.module.scss";
import PhilippineChart from "../../Components/ComponentDailyCases/PhilippineChart";

const ChartWrapper = () => {
  const languageContext = useContext(LanguageContext);
  const [Open, setOpen] = useState(false);
  const textAreaRefIframe = useRef(null);
  const textAreaRefLink = useRef(null);
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 });
  const [flag, setFlag] = useState(true);
  const [casereportdata, setCaseReportData] = useState([]);
  const [covidstatusdata, setCovidStatusData] = useState([]);
  axios.defaults.headers.post["Content-Type"] = "application/json";
  const fetchDataFromSheet = async () => {
    const casereport_ID = "1waFcjUhdeVGQoVBc8X2dJNaB6Vtfi4_utdsK1nP37Vg";
    const covidstat_ID = "1tI7Gmk_LccBXaAmmiSe6adPB0RAep7YMjgCdV0_7zdk";
    const crurl = `https://spreadsheets.google.com/feeds/cells/${casereport_ID}/1/public/full?alt=json`;
    let casereport_res = await axios.get(crurl);
    // console.log(casereport_res)
    const sturl = `https://spreadsheets.google.com/feeds/cells/${covidstat_ID}/1/public/full?alt=json`;
    let covidst_res = await axios.get(sturl);
    // console.log(covidst_res)
  };

  useEffect(() => {
    fetchDataFromSheet();
    setFlag(false);
  }, [flag]);

  function copyToClipboardIframe(e) {
    textAreaRefIframe.current.select();
    document.execCommand("copy");
    e.target.focus();
    Event(
      "Copy Iframe Link Button to Embedded page",
      `<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casetimeline/?lang=${
        languageContext.language.id
      }&caseID=${caseID}&locationDetail=${mapDetail}"></iframe>`,
      "Components/ComponentTimeline"
    );
  }

  function copyToClipboardLink(e) {
    textAreaRefLink.current.select();
    document.execCommand("copy");
    e.target.focus();
    Event(
      "Copy Link Button to Embedded page",
      `${currenturl}/casetracing/casetimeline/?lang=${
        languageContext.language.id
      }&caseID=`,
      "Components/ComponentTimeline"
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
    Event(
      "Download Share Button",
      `Click download & share button to open modal box for Timeline Component`,
      "Components/ComponentTimeline"
    );
  };

  const handleClosetrace = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" p={1}>
            <Typography variant="h6">
              New Daily Covid-19 Cases in the Philippines
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            p={1}
            className={styles.iframecontainer}
          >
            <PhilippineChart
              size={{ width: 700, height: 300 }}
              numDays={90}
              color={{ primary: "rgb(154, 235, 180)" }}
            />
            {/* <Iframe
                            url="https://datastudio.google.com/embed/u/0/reporting/d88eac16-94c5-40f6-bab9-945c561af18e/page/7sOqB"
                            width="100%"
                            height="100%"
                            styles={{ height: "25px" }}
                            className={styles.iframe}
                            allowFullScreen
                        /> */}
          </Box>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="center" m={4} p={1}>
        <DownShareButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ReplyIcon />}
          onClick={handleClickOpen}
        >
          <Box p={1}>
            <Text tid="downloadandshare" />
          </Box>
        </DownShareButton>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClosetrace}
        aria-labelledby="customized-dialog-title"
        open={Open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClosetrace}>
          <Box display="flex" flexDirection="row">
            <span className="dialogtitle">
              <Text tid="downloadandshare" /> -{" "}
              <Text tid="travelhistorytimeline" />
            </span>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box style={{ height: "2vh" }} />
          <Typography variant="h6" gutterBottom>
            <Text tid="shareembeddedphrase" />
          </Typography>
          <Typography>
            <Text tid="copyandpastephrase" />
          </Typography>
          <Grid container>
            <Grid item md={12} xs={12} style={textareacontainerstyle}>
              <TextAreaAutosize
                ref={textAreaRefIframe}
                id="filled-multiline-static"
                rowsMin={3}
                cols={20}
                // value={`<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/chartpreview/chart?lang=${languageContext.language.id}&"></iframe>`}
                value={`<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/philippine/chart?lang=en"></iframe>`}
                rows="4"
                variant="filled"
                className="iframearea"
              />
              <Box
                display="flex"
                justifyContent="flex-end"
                style={modalboxcopystyle}
              >
                <IconButton
                  className="standard-button"
                  onClick={copyToClipboardIframe}
                  style={textareacontainerstyle_iconbutton}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ height: "2vh" }} />
          <Typography variant="h6" gutterBottom>
            <Text tid="sharesocialphrase" />
          </Typography>
          <Typography>
            <Text tid="copylinkphrase" />
          </Typography>
          <Grid container m={1}>
            <Grid item xs={12} md={12} style={textareacontainerstyle}>
              <Box display="flex" flexDirection="row">
                <TextAreaAutosize
                  ref={textAreaRefLink}
                  id="filled-multiline-static"
                  cols={20}
                  // value={`${currenturl}/philippine/chart?lang=${languageContext.language.id}`}
                  value={`${currenturl}/philippine/chart?lang=en`}
                  className="linkarea"
                />
                <IconButton
                  className="standard-button"
                  onClick={copyToClipboardLink}
                  style={textareacontainerstyle_iconbutton2}
                >
                  <FileCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChartWrapper;
