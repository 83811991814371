import React, { useState, useEffect } from 'react';
import { makeNetworkData } from '../../utils'
import { cluster, hierarchy } from 'd3-hierarchy'
import { path } from 'd3-path'
import { elementBackgroundColor, lineGrey } from '../../Elements'

export default (props) => {
  const { allData, size, currentID, getSvgWrapper } = props;
  let correctedSize = (size.width || size.height) ? size : { width: 400, height: 400 };
  const width = correctedSize.width > 700 ? 700 : (correctedSize.width < 500 ? 500 : correctedSize.width);
  const height = correctedSize.height > 700 ? 700 : (correctedSize.height < 500 ? 500 : correctedSize.height);
  const margin = { left: 20, right: 20, top: 20, bottom: 20 }
  const radius = 0.5 * (width > height ? (height - margin.top - margin.bottom) : (width - margin.left - margin.right));

  // const angleCorrection = 2 * Math.PI / allData.length;
  const angleCorrection = 0;
  const nodeRadius = width / 200;
  const nodeFontSize = width / 1000;

  const tree = cluster().size([2 * Math.PI, radius - 100]);
  const networkData = makeNetworkData(allData);

  const root = tree(hierarchy(networkData.tree))

  const [hoverItem, setHoverItem] = useState({ item: null, isHighlight: false });


  // console.log(networkData);

  const xAccessor = (d) => {
    // console.log(d)
    var angle = d.x - angleCorrection, radius = d.y;
    return radius * Math.cos(angle);
  }

  const yAccessor = (d) => {
    var angle = d.x - angleCorrection, radius = d.y;
    return radius * Math.sin(angle);
  }

  const getNodeCircleLayoutTransform = (node) => {
    const transform = { rotate: node.x * 180 / Math.PI - 90, translate: node.y };
    return "rotate(" + transform.rotate + ") translate(" + transform.translate + ",0)";
  }

  const getPathForEdge = (edge, nodes) => {
    // console.log(edge);
    let edgePath = new path();

    // console.log(nodes[edge.source].data.confirmed_case_id,nodes[edge.source].x,nodes[edge.source].y)


    edgePath.moveTo(xAccessor(nodes[edge.source - 1]), yAccessor(nodes[edge.source - 1]));

    edgePath.quadraticCurveTo(0, 0, xAccessor(nodes[edge.target - 1]), yAccessor(nodes[edge.target - 1]));

    // console.log(edgePath);
    return edgePath;
  }

  const handleMouseEnter = (item) => {
    // console.log(item.data);
    setHoverItem({ item: +item.data.confirmed_case_id, isHighlight: true })
  }

  const handleMouseLeave = (item) => {
    // console.log(item);
    setHoverItem({ item: null, isHighlight: false })

  }

  useEffect(() => {
    if (currentID) {
      setHoverItem({ item: +currentID, isHighlight: true })
    }
  }, [currentID])

  return (
    <div className={'NetworkDiagramWrapper'}>
      <svg style={{ width: width, height: height, background: '#ffffff' }} ref={ref => getSvgWrapper(ref)} >
        <g transform={"translate(" + (width / 2) + "," + (height / 2) + ") rotate(-90)"} >
          {
            networkData && networkData.edges && root && root.children
              ? networkData.edges.map((edge, key) => {
                return <path
                  key={"path" + edge.source + "To" + edge.target}
                  d={getPathForEdge(edge, root.children)}
                  fill='none'
                  stroke={
                    (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
                      ? elementBackgroundColor
                      : lineGrey
                  }
                  strokeWidth={
                    (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
                      ? 3
                      : 1
                  }
                  strokeOpacity={
                    (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
                      ? 1
                      : 0.3
                  }
                />
              })
              : null
          }
        </g>
        <g transform={"translate(" + (width / 2) + "," + (height / 2) + ")"} >
          {
            root && root.children
              ? root.children.map((node, key) => {
                return <g transform={getNodeCircleLayoutTransform(node)} key={'nodeWrapper' + key}>
                  <circle
                    key={"networkCircle" + key}
                    r={nodeRadius}
                    fill={elementBackgroundColor}
                    onMouseEnter={() => handleMouseEnter(node)}
                    onMouseLeave={() => handleMouseLeave(node)}
                  />
                  <text
                    key={"netWorkLabel" + key}
                    dy="0.3em"
                    dx={(node => node.x > Math.PI ? "-1em" : "1em")(node)}
                    transform={"rotate(" + (node => node.x > Math.PI ? 180 : 0)(node) + ")"}
                    style={{
                      textAnchor: (node => node.x > Math.PI ? "end" : "start")(node),
                      fill: "#000000",
                      fontWeight: "bold",
                      fontSize: nodeFontSize + "em"
                    }}>
                    {+node.data.confirmed_case_id}
                  </text>
                </g>
              })
              : null
          }
        </g>


      </svg>
    </div>
  )
}
