import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, Card } from "@material-ui/core";
import { PlayCircleFilled } from "@material-ui/icons";
import * as routes from "../../Routes";
import { NavLink, PNGButton } from "../../Elements";
import { Text } from "../../LangProvider";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: "94.99%",
    height: "50vh",
    maxHeight: "480px",
    textAlign: "center",
  },

  media: {
    height: "99.9999%",
    // width: '99.9999%',
    textAlign: "center",
  },
  casecontainer: {
    padding: "0.4rem",
    margin: "0.4em",
    background: "#fff",
  },
}));

const PageCambodiaRegionalChart = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Card>
          <Grid container spacing={1} className={classes.casecontainer}>
            <Grid item xs={12} md={7} lg={8}>
              <div className={classes.card}>
                <img
                  src="img/cambodia_regional.png"
                  alt="Cambodia"
                  className={classes.media}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Box display="flex" m={2} p={2} id="mainmap">
                <Box p={1}>
                  <h2>
                    <Text tid="chartpreviewtitlekhregional" />{" "}
                  </h2>
                  <br />
                  <Typography variant="body1" gutterBottom>
                    <Text tid="chartpreviewphrasekhregional" />
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                m={2}
                p={2}
                id="mainmap"
              >
                <NavLink to={`${routes.KHREGIONALCHART}?lang=en`}>
                  <PNGButton
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<PlayCircleFilled />}
                  >
                    <Box p={1}>
                      <Text tid="btnexplore" />
                    </Box>
                  </PNGButton>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default PageCambodiaRegionalChart;
