import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import { Grid, Box, Radio, RadioGroup, FormControlLabel, FormControl, IconButton, AppBar } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { TableChart as TableChartIcon, Visibility as VisibilityIcon, Description as DescriptionIcon, ChevronRight, ChevronLeft } from '@material-ui/icons'
import { CSVLink } from 'react-csv';
import Select from 'react-select'
import ReactGA from 'react-ga'
import { languageOptions } from '../../Languages'
import { Text, LanguageContext, getText } from '../../LangProvider';
import { getFilteredRoute, getRouteSegments } from '../../utils';
import ComponentTimeline from '../../Components/ComponentTimeline';
import ComponentMap from '../../Components/ComponentMap'
import OverviewBox from './OverviewBox'
import ComponentDiagram from '../../Components/ComponentDiagram'
import { SIMPLEButton, DisplayResources, DisplayEmptyBox, WHITEButton, DialogContent, DialogTitle, translateNumber, selectstyle, formatNum, WhFab, Event } from '../../Elements'


const defaultColumnProperties = {
    filterable: true,
    width: "100%"
};

const selectors = Data.Selectors;

const {
    NumericFilter,
    MultiSelectFilter
} = Filters;


const URLFormatter = ({ value }) => {
    return <a href={value} target="_blank" rel="noopener noreferrer" >{value}</a>;
}

const TableDesktop = ({ rows, routes }) => {
    ReactGA.initialize('UA-159319358-2')
    let history = useHistory()
    const location = useLocation()
    const values = queryString.parse(location.search)
    const [last, setLast] = useState('')
    let id = values.caseID
    let lang = values.lang
    const languageContext = useContext(LanguageContext)

    useEffect(() => {
        if (lang) {
            const selectedLanguage = languageOptions.find(item => item.id === lang)
            languageContext.setLanguage(selectedLanguage)
        }
    }, [lang, languageContext])

    let options = []

    rows.map(row => {
        let formatted = { value: row.confirmed_case_id, label: row.confirmed_case_id }
        return options.push(formatted)
    })

    const columns = [
        {
            key: "confirmed_case_id",
            name: languageContext.language.id === 'en' ? "Confirmed Case ID" : "အတည်ပြုနံပါတ်",
            resizable: true,
            sortDescendingFirst: true
        },
        {
            key: "age",
            name: languageContext.language.id === 'en' ? "Age" : "အသက်",
            resizable: true,
            filterRenderer: NumericFilter
        },
        {
            key: languageContext.language.id === 'en' ? "sex_en" : "sex_mm",
            name: languageContext.language.id === 'en' ? "Sex" : "လိင်",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: languageContext.language.id === 'en' ? "current_status_en" : "current_status_mm",
            name: languageContext.language.id === 'en' ? "Current Status" : "လက်ရှိအခြေအနေ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: "announced_date",
            name: languageContext.language.id === 'en' ? "Announced Date" : "ကြေငြာသည့်နေ့စွဲ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: "treatment_facility",
            name: languageContext.language.id === 'en' ? "Treatment Facility" : "ကုသမှုနေရာ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        // {
        //     key: "remarks",
        //     name: languageContext.language.id === 'en' ? "Remarks" : "မှတ်ချက်",
        //     resizable: true
        // },
        {
            key: "mohs_ref",
            name: languageContext.language.id === 'en' ? "MOHS Reference" : "ကျန်းမာရေးနှင့်အားကစားဝန်ကြီးဌာန",
            resizable: true,
            formatter: URLFormatter
        }
    ].map(c => ({ ...c, ...defaultColumnProperties }))

    const [filters, setFilters] = useState({})
    const [caseID, setCaseID] = useState(id ? id : '01')
    const [currentRouteData, setCurrentRouteData] = useState(null)
    const [segments, setSegments] = useState(null)
    const [mapDetail, setMapDetail] = useState("specificPath")
    const [selectedValue, setSelectedValue] = useState(id);
    const [currentUser, setCurrentUser] = useState({})
    const [Open, setOpen] = useState(false)

    const filteredRows = getRows(rows, filters)
    const handleFilterChange = filter => filters => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }

    function getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }

    const handleCellActions = (row) => [
        {
            icon: <IconButton color="primary" aria-label="upload picture" component="span">
                <VisibilityIcon />
            </IconButton>,
            callback: () => {
                setCaseID(row.confirmed_case_id)
                Event("Table View Icon Button", `selected ${row.confirmed_case_id} from table`, "TableWrapper/PageTableDesktop")
                history.push({
                    pathname: `${location.pathname}`,
                    search: `?caseID=${row.confirmed_case_id}&&lang=${languageContext.language.id}`
                })
                let host = []
                host.push(getSelectionByID(row.confirmed_case_id))
                // setSelectedrow(host);
            }
        }
    ];

    const handleMapDetailChange = (event) => {
        setMapDetail(event.target.value);
        Event("General or Map Detail FormControl", `selected value :${event.target.value}`, "TableWrapper/PageTableDesktop")
    }

    function getCellActions(column, row) {
        const cellActions = {
            confirmed_case_id: handleCellActions(row)
        };
        return row.confirmed_case_id ? cellActions[column.key] : null
    }

    function getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }


    useEffect(() => {
        if (routes && typeof +caseID == 'number') {
            const filteredRoute = getFilteredRoute(routes, caseID);
            setCurrentRouteData(filteredRoute);
        }
        if (caseID) {
            const host = rows.find(x => x.confirmed_case_id === caseID)
            if (host) {
                setCurrentUser(host)
            }
        }
    }, [caseID, selectedValue, routes, rows, id])

    useEffect(() => {
        rows.map(row => {
            return setLast(row.confirmed_case_id)
        })
    }, [routes, rows])


    useEffect(() => {
        if (currentRouteData)
            setSegments(getRouteSegments(currentRouteData));
    }, [caseID, currentRouteData])

    const getSelectionByID = (id) => {
        return rows.find(x => x.confirmed_case_id === id)
    }


    const handleSelectChange = e => {
        Event("Select Button", `selected ${e.value} using select Button`, "TableWrapper/PageTableDesktop")
        setCaseID(e.value)
        setSelectedValue(e.value)
        history.push({
            pathname: `${location.pathname}`,
            search: `?caseID=${e.value}&lang=${languageContext.language.id}`
        })
    }

    const handleChevronRight = () => {
        if (caseID === `${rows.length}`) {} else {
            let val = formatNum(parseInt(caseID) + 1)
            Event("Right Floating Button", `selected ${val} using Right Floating Button`, "TableWrapper/PageTableDesktop")
            setCaseID(val)
            setSelectedValue(val)
            history.push({
                pathname: `${location.pathname}`,
                search: `?caseID=${val}&lang=${languageContext.language.id}`
            })
        }
    }

    const handleChevronLeft = () => {
        if (caseID === '01') {} else {
            let val = formatNum(parseInt(caseID) - 1)
            Event("Left Floating Button", `selected ${val} using Left Floating Button`, "TableWrapper/PageTableDesktop")
            setCaseID(val)
            setSelectedValue(val)
            history.push({
                pathname: `${location.pathname}`,
                search: `?caseID=${val}&lang=${languageContext.language.id}`
            })
        }
    }

    const handleClickOpen = () => {
        Event("Table Button", `clicked table button for Table Modal Box`, "TableWrapper/PageTableDesktop")
        setOpen(true)
    }

    const handleClose = () => {
        Event("Table Button", `close Table Modal Box`, "TableWrapper/PageTableDesktop")
        setOpen(false)
    }


    return (
        <div>
            <Box display="flex" justifyContent="center" >
                <Box p={1} style={{ textAlign: 'center' }} >
                    <h1> <Text tid="apptitle" /> </h1>
                    <p style={{ marginTop: '1rem' }}> <Text tid="chooseidphrase" /></p>
                </Box>
            </Box>
            <AppBar position="sticky" style={{ background: '#9ABDEB', color: '#000000', boxShadow: 'none' }}>
                <Grid container spacing={3} className="stickycontainer">
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" p={1}>
                            <WhFab size="medium" onClick={handleChevronLeft}>
                                <ChevronLeft />
                            </WhFab>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box display="flex" justifyContent="center" m={1} bgcolor="background.paper" style={{ borderRadius: '0.2rem' }}>
                            <h3> <Text tid="patientcase" /> - {}  </h3>
                            <div style={{ width: '8rem' }}><Select
                                placeholder={languageContext.language.id === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                value={selectedValue}
                                options={options}
                                onChange={handleSelectChange}
                                styles={selectstyle}
                                isSearchable={true}
                            /></div>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box display="flex" justifyContent="flex-start" p={1}>
                            <WhFab size="medium" onClick={handleChevronRight}>
                                <ChevronRight />
                            </WhFab>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-start" >
                            <WHITEButton variant="contained" color="primary" size="large" startIcon={<TableChartIcon />} onClick={handleClickOpen}>
                                <Text tid="choosefromtable" />
                            </WHITEButton>
                        </Box>
                    </Grid>
                </Grid>
            </AppBar>
            <Box style={{ height: '5vh' }}>
            </Box>
            <Grid container spacing={2} style={{ background: '#fff' }}>
                <Grid item xs={12} style={{ margin: '0rem 2rem 0rem 2rem' }}>
                    <h1 style={{ textAlign: 'center', padding: '1rem' }}><Text tid="patientcase" /> {languageContext.language.id === 'en' ? caseID : translateNumber(parseInt(caseID))}</h1>
                    <OverviewBox currentUser={currentUser} caseID={caseID} />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" p={1} m={1} bgcolor="background.paper">
                        <h2><Text tid="patientcase" /> {languageContext.language.id === 'en' ? caseID : translateNumber(parseInt(caseID))} <Text tid="patienttravelhistory" /> </h2>
                    </Box>
                </Grid>
            </Grid>
            {caseID && caseID.length ? (
                <>{segments ? (<>{segments.length > 0 ? (<>
                    <Grid container style={{ marginBottom: "2.2vh" }}>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                                <Box p={1} display="flex" justifyContent="flex-start" >


                                    {languageContext.language.id === 'en' ? (<>
                                        <h4>Last updated on 28/08/2020 </h4>
                                    </>) : (<>
                                        <h4>၂၈/၀၈/၂၀၂၀ တွင်နောက်ဆုံးရေးသားခဲ့သည်။</h4>
                                    </>)}


                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" flexWrap="wrap">
                                <Box p={1} display="flex" justifyContent="flex-end" >
                                    <FormControl component="fieldset">
                                        <Text tid="mapdetaillabel" />
                                        <RadioGroup aria-label="locationDetail" name="locationDetailSelect" value={mapDetail} onChange={handleMapDetailChange}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <FormControlLabel value="specificPath" control={<Radio />} label={getText("mapdetailspecific", languageContext)} />
                                                <FormControlLabel value="generalPath" control={<Radio />} label={getText("mapdetailgeneral", languageContext)} />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <ComponentTimeline segments={segments} caseID={caseID} mapDetail={mapDetail} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ComponentMap segments={segments} caseID={caseID} mapDetail={mapDetail} lang={languageContext.language.id} />
                        </Grid>
                        <Grid item xs={12}>
                            <ComponentDiagram allData={rows} filteredData={filteredRows} caseID={caseID} />
                        </Grid>
                    </Grid>
                </>) : (<><DisplayEmptyBox /></>)}
                </>
                ) : null}</>
            ) : null}
            <Dialog fullWidth={true} maxWidth={"xl"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={Open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <Text tid="postivecases" />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <ReactDataGrid
                                columns={columns}
                                rowGetter={i => filteredRows[i]}
                                rowsCount={filteredRows.length}
                                minHeight={400}
                                toolbar={
                                    <Toolbar enableFilter={true}>
                                        {<SIMPLEButton className="csvdownload" size="small" startIcon={<DescriptionIcon />} onClick={() => Event("Download CSV", `covid19_myanmar_positive_patients_case_1_to_${last}.csv`, "TableWrapper/PageTableDesktop")}>
                                            <CSVLink data={filteredRows} filename={`covid19_myanmar_positive_patients_case_1_to_${last}.csv`}><Text tid="btndata" /></CSVLink>
                                        </SIMPLEButton>}
                                    </Toolbar>}
                                getCellActions={getCellActions}
                                onAddFilter={filter => setFilters(handleFilterChange(filter))}
                                onClearFilters={() => setFilters({})}
                                getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
                                enableRowSelect={null}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <DisplayResources />
        </div>
    )
}

export default TableDesktop
