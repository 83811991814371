export default (dataSet) => {

  // console.log(dataSet);

  const edges = [];

  dataSet.forEach((item,i) => {
    const contacts = item.contact_by.split('|');
    
    contacts.forEach((contact,j) => {
      const contactPID = parseInt(contact);
      if(contactPID && contactPID < dataSet.length) {
        edges.push({
          source: contactPID,
          target: +item.confirmed_case_id
        })
      }
    })
  })

  return {
    nodes: dataSet,
    edges,
    tree: {name: 'all',children: dataSet}
  };

}