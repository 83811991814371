import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box, Grid, RadioGroup, Radio, FormControl, FormControlLabel, IconButton } from '@material-ui/core'
import { TableChart as TableChartIcon, Visibility as VisibilityIcon, Description as DescriptionIcon, ChevronRight, ChevronLeft } from '@material-ui/icons'
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import { CSVLink } from 'react-csv';
import Select from 'react-select'
import ReactGA from 'react-ga'
import { languageOptions } from '../../Languages'
import { Text, LanguageContext, getText } from '../../LangProvider';
import { getFilteredRoute, getRouteSegments } from '../../utils';
import ComponentTimeline from '../../Components/ComponentTimeline';
import ComponentMap from '../../Components/ComponentMap'
import ComponentNetwork from '../../Components/ComponentDiagram'
import OverviewBox from '../TableWrapper/OverviewBox'
import { SIMPLEButton, DisplayResources, WHITEButton, translateNumber, formatNum, WhFab, Event, DisplayEmptyBox } from '../../Elements'
import useDimensions from "react-use-dimensions";

const defaultColumnProperties = {
    filterable: true,
    width: "100%"
};

const selectors = Data.Selectors;

const {
    NumericFilter,
    MultiSelectFilter
} = Filters;


const URLFormatter = ({ value }) => {
    return <a href={value}>{value}</a>;
}

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <>
            {value === index && <>{children}</>}
        </>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const PageTableMobile = ({ rows, routes }) => {
    ReactGA.initialize('UA-159319358-2')
    const theme = useTheme();
    const [value, setValue] = useState(0);
    let history = useHistory()
    const location = useLocation()
    const [last, setLast] = useState('')
    const values = queryString.parse(location.search)
    let id = values.caseID
    let lang = values.lang
    const languageContext = useContext(LanguageContext)
    // eslint-disable-next-line 
    const [netWorkDiagramRef, networkDiagramSize] = useDimensions();

    useEffect(() => {
        if (lang) {
            const selectedLanguage = languageOptions.find(item => item.id === lang)
            languageContext.setLanguage(selectedLanguage)
        }
    }, [lang, languageContext])

    let options = []

    rows.map(row => {
        let formatted = { value: row.confirmed_case_id, label: row.confirmed_case_id }
        return options.push(formatted)
    })

    useEffect(() => {
        rows.map(row => {
            return setLast(row.confirmed_case_id)
        })
    }, [routes, rows])

    const columns = [
        {
            key: "confirmed_case_id",
            name: languageContext.language.id === 'en' ? "Confirmed Case ID" : "အတည်ပြုနံပါတ်",
            resizable: true,
            sortDescendingFirst: true
        },
        {
            key: "age",
            name: languageContext.language.id === 'en' ? "Age" : "အသက်",
            resizable: true,
            filterRenderer: NumericFilter
        },
        {
            key: languageContext.language.id === 'en' ? "sex_en" : "sex_mm",
            name: languageContext.language.id === 'en' ? "Sex" : "လိင်",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: languageContext.language.id === 'en' ? "current_status_en" : "current_status_mm",
            name: languageContext.language.id === 'en' ? "Current Status" : "လက်ရှိအခြေအနေ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: "announced_date",
            name: languageContext.language.id === 'en' ? "Announced Date" : "ကြေငြာသည့်နေ့စွဲ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        {
            key: "treatment_facility",
            name: languageContext.language.id === 'en' ? "Treatment Facility" : "ကုသမှုနေရာ",
            resizable: true,
            filterRenderer: MultiSelectFilter
        },
        // {
        //     key: "remarks",
        //     name: languageContext.language.id === 'en' ? "Remarks" : "မှတ်ချက်",
        //     resizable: true
        // },
        {
            key: "mohs_ref",
            name: languageContext.language.id === 'en' ? "MOHS Reference" : "ကျန်းမာရေးနှင့်အားကစားဝန်ကြီးဌာန",
            resizable: true,
            formatter: URLFormatter
        }
    ].map(c => ({ ...c, ...defaultColumnProperties }))

    const style = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? 0 : 0,
            fontFamily: 'Noto Serif',
            padding: '0.3rem',
            fontSize: '1em',
            color: '#000',
            placeholder: {
                color: '#000'
            },
            width: '100%',
            // This line disable the blue border
            boxShadow: state.isFocused ? 0 : 0,
            "&:hover": {
                border: state.isFocused ? 0 : 0
            }
        })
    };

    const [filters, setFilters] = useState({})
    const [caseID, setCaseID] = useState(id ? id : '01')
    const [currentRouteData, setCurrentRouteData] = useState(null)
    const [segments, setSegments] = useState(null)
    const [mapDetail, setMapDetail] = useState("specificPath");
    const [selectedValue, setSelectedValue] = useState(caseID);
    const [currentUser, setCurrentUser] = useState({})
    const filteredRows = getRows(rows, filters)
    const handleFilterChange = filter => filters => {
        const newFilters = { ...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }




    function getRows(rows, filters) {
        return selectors.getRows({ rows, filters });
    }


    const handleCellActions = (row) => [
        {
            icon: <IconButton color="primary" aria-label="upload picture" component="span">
                <VisibilityIcon />
            </IconButton>,
            callback: () => {
                setCaseID(row.confirmed_case_id)
                Event("Table View Icon Button", `selected ${row.confirmed_case_id} from table`, "TableWrapper/PageTableMobile")
                history.push({
                    pathname: `${location.pathname}`,
                    search: `?caseID=${row.confirmed_case_id}&&lang=${languageContext.language.id}`
                })
                setValue(0)
                let host = []
                host.push(getSelectionByID(row.confirmed_case_id))
                // setSelectedrow(host);
            }
        }
    ];

    const handleMapDetailChange = (event) => {
        setMapDetail(event.target.value)
        Event("General or Map Detail FormControl", `selected value :${event.target.value}`, "TableWrapper/PageTableMobile")
    }

    function getCellActions(column, row) {
        const cellActions = {
            confirmed_case_id: handleCellActions(row)
        };
        return row.confirmed_case_id ? cellActions[column.key] : null
    }

    function getValidFilterValues(rows, columnId) {
        return rows
            .map(r => r[columnId])
            .filter((item, i, a) => {
                return i === a.indexOf(item);
            });
    }


    useEffect(() => {
        if (routes && typeof +caseID == 'number') {
            const filteredRoute = getFilteredRoute(routes, caseID);
            setCurrentRouteData(filteredRoute);
        }
        if (caseID) {
            const host = rows.find(x => x.confirmed_case_id === caseID)
            if (host) {
                setCurrentUser(host)
            }
        }
    }, [caseID, routes, rows, id])

    useEffect(() => {
        if (currentRouteData)
            setSegments(getRouteSegments(currentRouteData));
    }, [currentRouteData])


    const getSelectionByID = (id) => {
        return rows.find(x => x.confirmed_case_id === id)
    }

    const handleSelectChange = e => {
        setCaseID(e.value)
        setSelectedValue(e.value)
        history.push({
            pathname: `${location.pathname}`,
            search: `?caseID=${e.value}&&lang=${languageContext.language.id}`
        })
    }

    const handleChevronRight = () => {
        if (caseID === `${rows.length}`) { } else {
            let val = formatNum(parseInt(caseID) + 1)
            setCaseID(val)
            setSelectedValue(val)
            history.push({
                pathname: `${location.pathname}`,
                search: `?caseID=${val}&lang=${languageContext.language.id}`
            })
        }
    }

    const handleChevronLeft = () => {
        if (caseID === '01') { } else {
            let val = formatNum(parseInt(caseID) - 1)
            setCaseID(val)
            setSelectedValue(val)
            history.push({
                pathname: `${location.pathname}`,
                search: `?caseID=${val}&lang=${languageContext.language.id}`
            })
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        Event("Mobile Tabs", `switched to tab value ${newValue}`, "TableWrapper/PageTableMobile")
    }


    const handleChangeIndex = (index) => {
        setValue(index)
    }

    return (
        <>
            <Box display="flex" justifyContent="center" >
                <Box p={1} style={{ textAlign: 'center' }} >
                    <h1> <Text tid="apptitle" /> </h1>
                    <p style={{ marginTop: '1rem' }}> <Text tid="chooseidphrase" /></p>
                </Box>
            </Box>
            <Box style={{ height: '2vh' }}>
            </Box>

            {caseID && caseID.length ? (<>
                <AppBar position="sticky" style={{ background: '#9ABDEB', color: '#000000', boxShadow: 'none' }}>
                    <Grid container spacing={2} style={{ background: 'white', padding: '0.1rem', boxShadow: 'none' }}>
                        <Grid item xs={3}>
                            <Box display="flex" justifyContent="flex-end" p={1}>
                                <WhFab size="medium" onClick={handleChevronLeft}>
                                    <ChevronLeft />
                                </WhFab>
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Box display="flex" direction="row" p={1} >
                                <h4> <Text tid="patientcase" /> </h4>
                                <div style={{ width: '10rem' }}><Select
                                    placeholder={languageContext.language.id === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                    value={selectedValue}
                                    options={options}
                                    onChange={handleSelectChange}
                                    styles={style}
                                    isSearchable={true}
                                /></div>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box display="flex" justifyContent="flex-start" p={1}>
                                <WhFab size="medium" onClick={handleChevronRight}>
                                    <ChevronRight />
                                </WhFab>
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0.4rem' }}>
                            <WHITEButton variant="contained" color="primary" size="small" startIcon={<TableChartIcon />} id="mobilebutton" onClick={() => setValue(3)}>
                                <Text tid="choosefromtable" />
                            </WHITEButton>
                        </Grid>
                    </Grid>
                </AppBar>
            </>) : null
            }
            <Box style={{ height: '2vh' }}></Box>
            <Grid container spacing={2} style={{ background: '#fff' }}>
                <Grid item xs={12} style={{ margin: '0rem 0.2rem 0rem 0.2rem' }}>
                    <OverviewBox currentUser={currentUser} caseID={caseID} />

                    {languageContext.language.id === 'en' ? (<>
                        <h4 style={{ textAlign: 'center' }}>Last updated on 28/08/2020 </h4>
                    </>) : (<>
                        <h4 style={{ textAlign: 'center' }}>၂၈/၀၈/၂၀၂၀ တွင်နောက်ဆုံးရေးသားခဲ့သည်။</h4>
                    </>)}

                </Grid>
            </Grid>

            {caseID && caseID.length ? (<>
                {segments && segments.length > 0 ? (<>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label={<Text tid="timeline" />} {...a11yProps(0)} />
                            <Tab label={<Text tid="map" />} {...a11yProps(1)} />
                            <Tab label={<Text tid="networkdiagram" />} {...a11yProps(2)} />
                            <Tab label={<Text tid="table" />} {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            {caseID && caseID.length ? (
                                <><Grid container style={{ background: 'white' }}>

                                    <Box display="flex" direction="row" style={{ marginTop: "2.2vh", marginBottom: "2.2vh", background: 'white' }} flexWrap="wrap">

                                        <Box style={{ height: '4vh' }}>
                                        </Box>

                                        <Box p={1} display="flex" justifyContent="flex-end" >
                                            <FormControl component="fieldset">
                                                <Text tid="mapdetaillabel" />
                                                <RadioGroup aria-label="locationDetail" name="locationDetailSelect" value={mapDetail} onChange={handleMapDetailChange}>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <FormControlLabel value="specificPath" control={<Radio />} label={getText("mapdetailspecific", languageContext)} />
                                                        <FormControlLabel value="generalPath" control={<Radio />} label={getText("mapdetailgeneral", languageContext)} />
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                    </Box>
                                    <Grid item xs={12}>
                                        <ComponentTimeline segments={segments} caseID={caseID} mapDetail={mapDetail} />
                                    </Grid>
                                </Grid>
                                </>
                            ) : (<><Box style={{ height: '2vh', marginTop: '3vh', marginBottom: '10vh' }}></Box></>)
                            }
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            {caseID && caseID.length ? (
                                <>
                                    <Grid container style={{ background: 'white' }}>
                                        <Box display="flex" style={{ marginTop: "2.2vh", marginBottom: "2.2vh", background: 'white' }} flexWrap="wrap">
                                            <Box p={1} display="flex" justifyContent="flex-end" >
                                                <FormControl component="fieldset">
                                                    <Text tid="mapdetaillabel" />
                                                    <RadioGroup aria-label="locationDetail" name="locationDetailSelect" value={mapDetail} onChange={handleMapDetailChange}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <FormControlLabel value="specificPath" control={<Radio />} label={getText("mapdetailspecific", languageContext)} />
                                                            <FormControlLabel value="generalPath" control={<Radio />} label={getText("mapdetailgeneral", languageContext)} />
                                                        </div>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Grid item xs={12}>
                                            <ComponentMap segments={segments} caseID={caseID} mapDetail={mapDetail} />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (<><Box style={{ height: '2vh', marginTop: '3vh', marginBottom: '10vh' }}></Box></>)
                            }
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <Grid container style={{ background: "white" }}>
                                <Grid item xs={12}>
                                    <ComponentNetwork
                                        allData={rows}
                                        filteredData={filteredRows}
                                        caseID={caseID}
                                        size={networkDiagramSize}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={3} dir={theme.direction}>
                            <ReactDataGrid
                                columns={columns}
                                rowGetter={i => filteredRows[i]}
                                rowsCount={filteredRows.length}
                                minHeight={400}
                                toolbar={
                                    <Toolbar enableFilter={true}>
                                        {<SIMPLEButton className="csvdownload" size="small" startIcon={<DescriptionIcon />} style={{ marginRight: '0.2rem' }} onClick={() => Event("Download CSV", `covid19_myanmar_positive_patients_case_1_to_${last}.csv`, "TableWrapper/PageTableMobile")}>
                                            <CSVLink data={filteredRows} filename={`covid19_myanmar_positive_patients_case_1_to_${last}.csv`}><Text tid="btndata" /></CSVLink>
                                        </SIMPLEButton>}
                                    </Toolbar>}
                                getCellActions={getCellActions}
                                onAddFilter={filter => setFilters(handleFilterChange(filter))}
                                onClearFilters={() => setFilters({})}
                                getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
                                enableRowSelect={null}
                            />
                        </TabPanel>
                    </SwipeableViews>

                </>) : (<> <DisplayEmptyBox /> </>)}
            </>) : null}
            <DisplayResources />
        </>
    );
}


export default PageTableMobile