import React, { useContext } from 'react'
import { Box } from '@material-ui/core'
import Moment from 'react-moment'
import { Text, LanguageContext } from '../../LangProvider';
import { dateValueFormatter, translateNumber } from '../../Elements'

const OverviewBox = ({ currentUser, caseID }) => {
    const languageContext = useContext(LanguageContext)

    const getBoxColor = (status) => {

        if (status === 'recovered') {
            return '#9AEBB4';
        } else if (status === 'deceased') {
            return '#EBA59A';
        } else {
            return '#ddd';
        }

    }

    return (
        <>
            <Box justifyContent="center" p={1} m={4} bgcolor={getBoxColor(currentUser.current_status_en)}>
                <Box p={1} m={2}>
                    {
                        languageContext.language.id === 'en' ? (<>

                            {
                                currentUser.current_status_en === 'recovered' ? (<>
                                    <p>
                                        <Text tid="phraserecovered1" />
                                        &nbsp;{parseInt(caseID)}
                                        &nbsp;<Text tid="phraserecovered2" />
                                        <strong>
                                            &nbsp;{parseInt(currentUser.age)}
                                            &nbsp;<Text tid="phraserecovered3" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;<Text tid="phraserecovered4" />
                                        &nbsp;
                                            <strong>
                                            {currentUser.treatment_facility}.
                                            </strong>
                                    </p>
                                </>) : (<></>)
                            }
                            {
                                currentUser.current_status_en === 'inpatient' ? (<>
                                    <p>
                                        <Text tid="phraseinpatient1" />
                                        &nbsp;{parseInt(caseID)}
                                        &nbsp;<Text tid="phraserecovered2" />
                                        <strong>
                                            &nbsp;{parseInt(currentUser.age)}
                                            &nbsp;<Text tid="phraserecovered3" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;<Text tid="phraseinpatient4" />
                                        <strong>
                                            &nbsp;{currentUser.treatment_facility}.
                                        </strong>
                                    </p>
                                </>) : (<></>)
                            }
                            {
                                currentUser.current_status_en === 'deceased' ? (<>
                                    <p>
                                        <Text tid="phrasedeceased1" />
                                        &nbsp;{parseInt(caseID)}
                                        &nbsp;<Text tid="phrasedeceased2" />
                                        <strong>
                                            &nbsp;{parseInt(currentUser.age)}
                                            &nbsp;<Text tid="phraserecovered3" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;
                                        <Text tid="phrasedeceased4" />
                                        <strong>
                                            &nbsp;{<Moment locale="en" format="DD/MM/YYYY">{dateValueFormatter(currentUser.discharged_or_deceased_date)}</Moment>}.
                                        </strong>
                                        &nbsp;<Text tid="phrasedeceased5" />
                                        <strong>
                                            &nbsp;{currentUser.treatment_facility}.
                                        </strong>
                                    </p>
                                </>) : (<></>)
                            }
                        </>) : (<>
                            {
                                currentUser.current_status_en === 'recovered' ? (<>
                                    <p>
                                        <Text tid="patientcase" /> &nbsp; {translateNumber(parseInt(caseID))}
                                        &nbsp;<Text tid="phraserecovered1" />
                                        <strong>
                                            &nbsp;{translateNumber(parseInt(currentUser.age))}
                                            &nbsp;<Text tid="phraserecovered2" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;<Text tid="phraserecovered3" />
                                        <strong>
                                            &nbsp;{currentUser.treatment_facility}
                                        </strong>
                                        &nbsp;<Text tid="phraserecovered4" />
                                    </p>
                                </>) : (<></>)
                            }
                            {
                                currentUser.current_status_en === 'inpatient' ? (<>
                                    <p>
                                        <Text tid="patientcase" /> &nbsp; {translateNumber(parseInt(caseID))}
                                        &nbsp;<Text tid="phraserecovered1" />
                                        <strong>
                                            &nbsp;{translateNumber(parseInt(currentUser.age))}
                                            &nbsp;<Text tid="phraserecovered2" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;<Text tid="phraserecovered3" />
                                        <strong>
                                            &nbsp;{currentUser.treatment_facility}
                                        </strong>
                                        <Text tid="phraseinpatient4" />
                                    </p>
                                </>) : (<></>)
                            }
                            {
                                currentUser.current_status_en === 'deceased' ? (<>
                                    <p>
                                        <Text tid="patientcase" />  &nbsp; {translateNumber(parseInt(caseID))}
                                        &nbsp;<Text tid="phraserecovered1" />
                                        <strong>
                                            &nbsp;{translateNumber(parseInt(currentUser.age))}
                                            &nbsp;<Text tid="phraserecovered2" />
                                            &nbsp;{currentUser.sex_en === 'm' ? (<><Text tid="man" /></>) : (<><Text tid="woman" /></>)}
                                        </strong>
                                        &nbsp;<Text tid="phraserecovered3" />
                                        <strong>
                                            &nbsp;{currentUser.treatment_facility}
                                        </strong>
                                        &nbsp;<Text tid="phrasedeceased4" />
                                        <strong>
                                            &nbsp;{<Moment locale="my" format="DD/MM/YYYY">{dateValueFormatter(currentUser.discharged_or_deceased_date)}</Moment>}&nbsp;
                                        </strong>
                                        <Text tid="phrasedeceased5" />
                                    </p>
                                </>) : (<></>)
                            }

                        </>)
                    }
                </Box>
            </Box>
        </>
    )
}

export default OverviewBox