const mm = {
    appname: 'ကိူဗစ်-၁၉ အချက်အလက်',
    appabout: 'အကြောင်းအရာ',
    apptitle: 'လူနာမှတ်တမ်းအလိုက် ခြေရာကောက်ခြင်း',
    appdescription1: 'ခရီးသွားမှတ်တမ်းမြေပုံ ကိုကြည့်ရန် မျက်လုံး',
    appdescription2: 'အိုင်ကွန်ပေါ်တွင် ကလစ်နှိပ်ပါ',
    patientcase: 'လူနာအမှတ်',
    patienttravelhistory: 'ခရီးသွားမှတ်တမ်း',
    btncode: 'ကုဒ်ယူရန်',
    btnimage: 'ပုံသိမ်းယူရန်',
    btndata: 'ဒေတာသိမ်းရန်',
    btntravel: 'ခရီးသွားမှတ်တမ်းသိမ်းယူရန်',
    appmaptitle: 'မြေပုံများ',
    apptooltitle: 'ကိရိယာများ',
    btnexplore: 'စူးစမ်းရန်',
    mapdetaillabel: 'တည်နေရာ အသေးစိတ်',
    mapdetailspecific: 'အသေးစိတ်',
    mapdetailgeneral: 'ယေဘုယျ',
    nodetails: 'ယခုဖော်ပြပါလူနာအတွက် ခရီးသွားအချက်အလက်များ မဖြည့်သွင်းရသေးပါ',
    table: 'ဇယား',
    networkdiagram: 'ကွန်ယက်ပုံ',
    timeline: 'အချိန်ဇယား',
    map: 'မြေပုံ',
    casetrackingphrase: 'ကျွန်ုပ်တို့၏ပထမဦးဆုံး ကိရိယာသည်မြန်မာနိုင်ငံတွင်အတည်ပြုသော ကိူဗစ်-၁၉ ဖြစ်ပွားမှုများ၏ခရီးသွားမှတ်တမ်းများကိုခြေရာခံသည်။',
    chooseidphrase: 'လူနာအမှတ်ရွေးချယ်ပါ။',
    choosefromtable: 'ဇယားမှ ရွေးချယ်ရန်',
    phraserecovered1: 'သည် အသက်',
    phraserecovered2: 'နှစ်',
    phraserecovered3: 'တစ်ဦးဖြစ်ပြီး ',
    phraserecovered4: 'တွင် ဆေးကုသမှု ခံယူခဲ့ပြီး ပြန်လည်သက်သာသွားပြီ ဖြစ်ပါသည်။',
    phraseinpatient1: 'The patient identfied as case number',
    phraseinpatient4: 'တွင် ဆေးကုသမှု ခံယူလျက်ရှိပါသည်။',
    phrasedeceased1: 'The patient identified as case number',
    phrasedeceased2: 'was a',
    phrasedeceased4: 'တွင် ဆေးကုသမှု ခံယူခဲ့ပြီး',
    phrasedeceased5: 'တွင် သေဆုံးခဲ့ပါသည်။',
    phraseend: '။ ',
    man: 'အမျိုးသား',
    woman: 'အမျိုးသမီး',
    downloadandshare: 'သိမ်းဆည်းရန် နှင့် ဖြန့်ဝေရန်',
    travelhistorytimeline: 'ခရီးသွားမှတ်တမ်း ၏ အချိန်ဇယား',
    travelhistorymap: 'ခရီးသွားမှတ်တမ်း မြေပုံ',
    and: ' နှင့် ',
    shareembeddedphrase: 'သင့်ဝက်ဘ်ဆိုက်အတွက် ကုဒ် ကိုရယူပါ',
    copyandpastephrase: 'သင်မြေပုံပေါ်စေလိုသော website ပေါ်တွင်ဤကုဒ်ကိုကူးယူပါ။',
    sharesocialphrase: 'ဒီစာမျက်နှာကိုလူမှုမီဒီယာတွင်မျှဝေရန်',
    copylinkphrase: 'သို့မဟုတ် မျှဝေရန် link ကိုကူးယူပါ။',
    postivecases: 'အတည်ပြုလူနာမှတ်တမ်းများ ',
    chartpreviewphrase: 'IWPR Covid Tools Phrase',
    chartpreviewtitle: 'IWPR Covid Tools Title',
    chartpreviewphrasekh: 'Covid-19 Cases in Cambodia',
    chartpreviewtitlekh: 'Cambodia',
    chartpreviewphraseph: 'Covid-19 Cases in the Philippines',
    chartpreviewtitleph: 'The Philippines',
    chartpreviewphrasekhregional: 'Covid-19 Cases in Cambodia',
    chartpreviewtitlekhregional: 'Cambodia Regional Chart'
};

export default mm;
