import React, { useContext, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import Iframe from "react-iframe";
import { useLocation } from "react-router";
import queryString from "query-string";
import ReactGA from "react-ga";
import { languageOptions } from "../../Languages";
import { Text, LanguageContext } from "../../LangProvider";
import { HelmetElement } from "../../Elements";
import styles from "./FullPageChart.module.scss";
import DailyCasesChart from "../../Components/ComponentDailyCases/DailyCasesChart";
import DailyCasesPhilippineChart from "../../Components/ComponentDailyCases/PhilippineChart";

const FullPageChart = () => {
  ReactGA.initialize("UA-159319358-2");
  const location = useLocation();
  const values = queryString.parse(location.search);
  const languageContext = useContext(LanguageContext);
  useEffect(() => {
    if (values && values.lang !== null && values.lang !== "") {
      const selectedLanguage = languageOptions.find(
        (item) => item.id === values.lang
      );
      languageContext.setLanguage(selectedLanguage);
    }
  }, [values, languageContext]);

  return (
    <>
      <HelmetElement name={`IWPR`} content="IWPR Tools" />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            p={1}
            bgcolor="background.paper"
            style={{ borderRadius: "0.2rem" }}
          >
            <h1>New Daily Covid-19 Cases in the Philippines</h1>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            p={1}
            bgcolor="background.paper"
            style={{ borderRadius: "0.2rem" }}
          >
            <DailyCasesPhilippineChart
              size={{ width: 700, height: 300 }}
              numDays={90}
              color={{ primary: "rgb(154, 235, 180)" }}
            />
            {/* <Iframe
                            url="https://datastudio.google.com/embed/u/0/reporting/d88eac16-94c5-40f6-bab9-945c561af18e/page/7sOqB"
                            position="absolute"
                            width="80%"
                            height="80%"
                            allowFullScreen
                            styles={{ height: "60vh" }}
                        /> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FullPageChart;
