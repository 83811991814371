import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { csv } from 'd3'
import dataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - confirmed_cases.csv'
import locationdataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - locations.csv'
import routesDataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - location_history.csv'
import EmbeddedTimeline from './embeddedTimeline'
import { HelmetElement } from '../../Elements'

export default function PageEmbeddedTimelineWrapper() {
    // eslint-disable-next-line 
    const [rows, setRows] = useState([])
    const [routes, setRoutes] = useState([])
    // eslint-disable-next-line 
    const [locations, setLocations] = useState([])
    const location = useLocation()
    const values = queryString.parse(location.search)
    let caseID = values.caseID
    let lang = values.lang
    let mapDetail = values.locationDetail

    const getLocation = (locations, route) => {
        for (let i = 0; i < locations.length; i++) {
            if (locations[i].location_id === route.location_id) return locations[i]
        }
        return null;
    }

    useEffect(() => {
        // Read Patients Data
        csv(dataset)
            .then((d, error) => {
                if (error) {

                } else {
                    let host = []
                    d.map(val => {
                        return host.push(val)
                    })

                    setRows(host);
                }
            })
            // Read Locations + Travel Routes Data and Merge
            .then(() => {
                Promise.all([csv(locationdataset), csv(routesDataset)])
                    .then((d, error) => {
                        if (error) {
                        } else {
                            let [locations, routes] = d;
                            routes = routes.map((route) => {
                                return { ...route, ...getLocation(locations, route) }
                            })
                            setLocations(locations);
                            setRoutes(routes);
                        }
                    })
            })
    }, [])


    return (
        <>
            <HelmetElement name={`Case ${caseID} Timeline`} content="covid19mm, case, timeline, thibi" />
            <EmbeddedTimeline rows={rows} lang={lang} caseID={caseID} routes={routes} mapDetail={mapDetail} />

        </>
    );
}