export const HOME = '/'
export const ABOUT = '/Page-about'
export const CASETRACING = '/casetracing'
export const CHARTPREVIEW = '/chartpreview'
export const KHREGIONALCHART = '/cambodia/regional'
export const PHCHART = '/philippine'
export const EMBEDDEDPHCHART = '/philippine/chart'
export const EMBEDDEDCHART = '/chartpreview/chart'
export const EMBEDDEDMAP = '/casetracing/casemap'
export const EMBEDDEDTIMELINE = '/casetracing/casetimeline'
export const EMBEDDEDNETWORK = '/casetracing/casenetwork'
export const CASESPECIFIC = '/casetracing/case'
