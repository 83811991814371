import React, { useEffect, useState } from 'react'
import { csv } from 'd3'
import { useMediaQuery } from 'react-responsive'
import dataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - confirmed_cases.csv'
import locationdataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - locations.csv'
import routesDataset from '../../Data/COVID-19 Myanmar Contact Tracing Data (Thibi) - location_history.csv'
import TableMobile from './tableMobile'
import TableDesktop from './tableDesktop'


export default function PageTableWrapper() {
    const [rows, setRows] = useState([])
    const [routes, setRoutes] = useState([])
    const [locations, setLocations] = useState([])

    const getLocation = (locations, route) => {
        for (let i = 0; i < locations.length; i++) {
            if (locations[i].location_id === route.location_id) return locations[i]
        }
        return null;
    }

    useEffect(() => {
        // Read Patients Data
        csv(dataset)
            .then((d, error) => {
                if (error) {

                } else {
                    let host = []
                    d.map(val => {
                        return host.push(val)
                    })

                    setRows(host);
                }
            })
            // Read Locations + Travel Routes Data and Merge
            .then(() => {
                Promise.all([csv(locationdataset), csv(routesDataset)])
                    .then((d, error) => {
                        if (error) {
                        } else {
                            let [locations, routes] = d;
                            routes = routes.map((route) => {
                                return { ...route, ...getLocation(locations, route) }
                            })
                            setLocations(locations);
                            setRoutes(routes);
                        }
                    })
            })
    }, [])

    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })

    return (
        <>
            {
                isDesktopOrLaptop ? <TableDesktop rows={rows} routes={routes} locations={locations} /> : <TableMobile rows={rows} routes={routes} locations={locations} />
            }

        </>
    );
}