import React from "react";
import { Route, useLocation } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import PageNavbar from "../Components/Navbar";
import PageFooter from "../Components/Footer";
import { LanguageProvider } from "../LangProvider";
import * as routes from "../Routes";
import PageAbout from "../Pages/About";
import PageHome from "../Pages/Home";
import PageEmbeddedMapWrapper from "../Pages/EmbeddedMap";
import PageSpecificCaseWrapper from "../Pages/SpecificTableWrapper";
import PageEmbeddedTimelineWrapper from "../Pages/EmbeddedTimeline";
import PageEmbeddedNetworkWrapper from "../Pages/EmbeddedNetworkDiagram";
import PageTableWrapper from "../Pages/TableWrapper";
import PageChartWrapper from "../Pages/ChartWrapper";
import PageEmbeddedChart from "../Pages/ChartWrapper/FullPageChart";
import PageEmbeddedPhilippineChart from "../Pages/ChartWrapper/FullPagePhilippineChart";
import PhilippineChart from "../Pages/PhilippineChart";
import CambodiaRegionalChart from "../Pages/ChartWrapper/CambodiaRegionalChart";

import "../styles/style.scss";

const Layout = () => {
  let location = useLocation();
  let geturl = [];
  let wantedstring = "";
  geturl = location.pathname.split("/");
  wantedstring = `/${geturl[1]}/${geturl[2]}/`;
  return (
    <>
      <LanguageProvider>
        {wantedstring !== "/philippine/chart/" &&
        wantedstring !== "/chartpreview/chart/" &&
        wantedstring !== "/casetracing/casemap/" &&
        wantedstring !== "/casetracing/casetimeline/" &&
        wantedstring !== "/casetracing/casenetwork/" ? (
          <>
            <Grid container className="layoutContainer">
              <Grid
                item
                xs={12}
                style={{ background: "#9ABDEB", height: "65vh" }}
              >
                {location.pathname !== `${routes.EMBEDDEDMAP}` &&
                  location.pathname !== `${routes.EMBEDDEDTIMELINE}` &&
                  location.pathname !== `${routes.EMBEDDEDCHART}` &&
                  location.pathname !== `${routes.EMBEDDEDPHCHART}` && (
                    <PageNavbar />
                  )}
                <Container maxWidth="lg" className="cases_container">
                  <Route
                    exact
                    path={routes.HOME}
                    component={() => <PageHome />}
                  />
                  <Route
                    exact
                    path={routes.ABOUT}
                    component={() => <PageAbout url="yayitworks" />}
                  />
                  <Route
                    exact
                    path={routes.CASESPECIFIC}
                    component={() => <PageSpecificCaseWrapper />}
                  />
                  <Route
                    exact
                    path={routes.CASETRACING}
                    component={() => <PageTableWrapper />}
                  />
                  <Route
                    exact
                    path={routes.CHARTPREVIEW}
                    component={() => <PageChartWrapper />}
                  />
                  <Route
                    exact
                    path={routes.PHCHART}
                    component={() => <PhilippineChart />}
                  />
                  <Route
                    exact
                    path={routes.KHREGIONALCHART}
                    component={() => <CambodiaRegionalChart />}
                  />
                </Container>
                {location.pathname !== `${routes.EMBEDDEDMAP}` &&
                  location.pathname !== `${routes.EMBEDDEDTIMELINE}` &&
                  location.pathname !== `${routes.EMBEDDEDCHART}` &&
                  location.pathname !== `${routes.EMBEDDEDPHCHART}` && (
                    <PageFooter />
                  )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Route
              exact
              path={routes.EMBEDDEDMAP}
              component={() => <PageEmbeddedMapWrapper />}
            />
            <Route
              exact
              path={routes.EMBEDDEDCHART}
              component={() => <PageEmbeddedChart />}
            />
            <Route
              exact
              path={routes.EMBEDDEDPHCHART}
              component={() => <PageEmbeddedPhilippineChart />}
            />
            <Route
              exact
              path={routes.EMBEDDEDTIMELINE}
              component={() => <PageEmbeddedTimelineWrapper />}
            />
            <Route
              exact
              path={routes.EMBEDDEDNETWORK}
              component={() => <PageEmbeddedNetworkWrapper />}
            />
            <Route
              exact
              path={routes.KHREGIONALCHART}
              component={() => <CambodiaRegionalChart />}
            />
          </>
        )}
      </LanguageProvider>
    </>
  );
};

export default Layout;
