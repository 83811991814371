import React, { useContext } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, CssBaseline, useScrollTrigger, Slide, IconButton, Drawer, List, ListItem, ListItemText, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useMediaQuery } from 'react-responsive'
import * as routes from '../../Routes'
import ReactGA from 'react-ga'
import { NavLink, Event } from '../../Elements'
import { languageOptions } from '../../Languages'
import { LanguageContext, Text } from '../../LangProvider'

import styles from './index.module.scss'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  rootmobile: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#9ABDEB',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#9ABDEB',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    background: '#9ABDEB',
  },
  rootdesk: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  endtitle: {
    fontSize: '0.95rem',
    fontWeight: 'bold',
    margin: '0.2rem',
    padding: '0.2rem',
    textTransform: 'none'
  },

  '@media (max-width: 480px)': {
    title: {
      flexGrow: 1,
    },
    endtitle: {
      fontWeight: 'bold',
      margin: '0.2rem',
      padding: '0.2rem'
    }
  },
}));



function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};


export default function PageNavbar() {
  ReactGA.initialize('UA-159319358-2')
  const classes = useStyles()
  const theme = useTheme()
  let history = useHistory()
  const location = useLocation()
  const values = queryString.parse(location.search)
  let lang = values.lang
  let caseID = 1
  if (values.caseID) {
    caseID = values.caseID
  }

  const [open, setOpen] = React.useState(false)
  const languageContext = useContext(LanguageContext)
  const handleLanguageChange = (event) => {
    Event("Language Switch", `selected ${event.target.value}`, "PageNavbar")
    const selectedLanguage = languageOptions.find(item => item.id === event.target.value)
    languageContext.setLanguage(selectedLanguage)
    history.push({
      pathname: `${location.pathname}`,
      search: `?caseID=${values.caseID === 'undefined' ? 1 : caseID}&&lang=${event.target.value ? event.target.value : lang}`
    })
  };


  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })

  return (
    <>
      {
        isDesktopOrLaptop ? (
          <>
            <div className={classes.rootdesk}>
              <AppBar position="static" style={{ background: 'transparent', color: '#000000', boxShadow: 'none' }}>
                <Toolbar>
                  <Typography color="inherit" className={classes.title}>
                    <NavLink to={routes.HOME}>
                      <Text tid="appname" />
                    </NavLink>
                  </Typography>
                  <Button edge="end" color="inherit" className={classes.endtitle} onClick={() => Event("About", `About Link on Navbar`, "PageNavbar")}>
                    <NavLink to={routes.ABOUT} >
                      <h4 style={{ fontSize: '1rem' }}>
                        <Text tid="appabout" />
                      </h4>
                    </NavLink>
                  </Button>
                    <Select
                      onChange={handleLanguageChange}
                      value={languageContext.language.id}
                      className={styles.lgSwitch}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          background: 'transparent'
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {languageOptions.map(item => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                          className={styles.lgSwitch_Options}
                        >
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                </Toolbar>
              </AppBar>
            </div>
          </>
        ) : (
            <>
              <div className={classes.rootmobile}>
                <CssBaseline />

                <HideOnScroll>
                  <AppBar
                    position="fixed"
                    style={{ background: '#9ABDEB', boxShadow: 'none', color: 'black' }}
                  >
                    <Toolbar>
                      <IconButton
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography color="inherit" className={classes.title}>
                        <NavLink to={routes.HOME}>
                          <Text tid="appname" />
                        </NavLink>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </HideOnScroll>


                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="left"
                  open={open}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                      ) : (
                          <ChevronRightIcon />
                        )}
                    </IconButton>
                  </div>

                  <List>
                    <NavLink to={routes.ABOUT} >
                      <ListItem button onClick={() => Event("About", `About Link on Navbar`, "PageNavbar")}>
                        {/* <ListItemIcon>
                          <HomeIcon className={styles.menuicon} />
                        </ListItemIcon> */}
                        <ListItemText>
                          <h4>
                            <Text tid="appabout" />
                          </h4>
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                    <ListItem button>
                      <select
                        onChange={handleLanguageChange}
                        value={languageContext.language.id}
                        className={styles.lgSwitch}
                      >
                        {languageOptions.map(item => (
                          <option
                            key={item.id}
                            value={item.id}
                            className={styles.lgSwitch_Options}
                          >
                            {item.text}
                          </option>
                        ))}
                      </select>
                    </ListItem>
                    {/* <NavLink href="#about">
                      <ListItem button>
                        <ListItemText className={styles.siteTitle}> DATA VISUALIZATIONS </ListItemText>
                      </ListItem>
                    </NavLink>
                    <NavLink href="#infographics">
                      <ListItem button>
                        <ListItemText className={styles.siteTitle}> INFOGRAPHICS </ListItemText>
                      </ListItem>
                    </NavLink> */}
                  </List>
                </Drawer>
              </div>
            </>
          )
      }</>
  );
}