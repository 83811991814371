import React, { useState, useRef, useContext } from 'react'
import { Grid, Box, Dialog, Typography, IconButton } from '@material-ui/core'
import TextAreaAutosize from '@material-ui/core/TextareaAutosize'
import { Image as ImageIcon, FileCopy as FileCopyIcon, Reply as ReplyIcon, Description as DescriptionIcon } from '@material-ui/icons'
import { CSVLink } from 'react-csv';
import useDimensions from "react-use-dimensions";
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga'
import { Text, LanguageContext } from '../../LangProvider';
import { filterGeneralRoutes } from '../../utils';
import { PNGButton, currenturl, DialogContent, DialogTitle, modalboxcopystyle, textareacontainerstyle, textareacontainerstyle_iconbutton2, textareacontainerstyle_iconbutton, DownShareButton, Event } from '../../Elements'
import SVGCaseTimeline from '../SVGCaseTimeline/SVGCaseTimeline'


const ComponentTimeline = ({ segments, caseID, mapDetail }) => {
    ReactGA.initialize('UA-159319358-2')
    const languageContext = useContext(LanguageContext)
    const [svgTimelineRef, setSVGTimelineRef] = useState(null)
    const [Open, setOpen] = React.useState(false)
    const [caseTimelineRef, caseTimelineSize] = useDimensions();
    const textAreaRefIframe = useRef(null)
    const textAreaRefLink = useRef(null)
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })

    function copyToClipboardIframe(e) {
        textAreaRefIframe.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Iframe Link Button to Embedded page", `<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casetimeline/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}"></iframe>`, "Components/ComponentTimeline")
    };

    function copyToClipboardLink(e) {
        textAreaRefLink.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Link Button to Embedded page", `${currenturl}/casetracing/casetimeline/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}`, "Components/ComponentTimeline")
    };


    const serialize = (svg) => {

        const xmlns = "http://www.w3.org/2000/xmlns/";
        const xlinkns = "http://www.w3.org/1999/xlink";
        const svgns = "http://www.w3.org/2000/svg";

        svg = svg.cloneNode(true);
        const fragment = window.location.href + "#";
        const walker = document.createTreeWalker(svg, NodeFilter.SHOW_ELEMENT, null, false);
        while (walker.nextNode()) {
            for (const attr of walker.currentNode.attributes) {
                if (attr.value.includes(fragment)) {
                    attr.value = attr.value.replace(fragment, "#");
                }
            }
        }
        svg.setAttributeNS(xmlns, "xmlns", svgns);
        svg.setAttributeNS(xmlns, "xmlns:xlink", xlinkns);
        const serializer = new window.XMLSerializer();
        const string = serializer.serializeToString(svg);
        return new Blob([string], { type: "image/svg+xml" });
    }



    const saveSVGTimeline = () => {
        if (svgTimelineRef) {
            let csvURL = window.URL.createObjectURL(serialize(svgTimelineRef));
            let tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', "Case_" + caseID + "_Timeline.svg");
            tempLink.click();
        }

        Event("Download Timeline History as SVG", `Case_${caseID}_Timeline.svg`, "Components/ComponentTimeline")
    }

    const handleClickOpen = () => {
        setOpen(true)
        Event("Download Share Button", `Click download & share button to open modal box for Timeline Component`, "Components/ComponentTimeline")
    }

    const handleClosetrace = () => {
        setOpen(false)
    }

    return (
        <>
            {
                caseID && caseID.length ? (
                    <>
                        <div className="card">
                            <Grid container className="tracingbox" id="tracingbox" ref={caseTimelineRef}>

                                {
                                    segments && caseTimelineSize
                                        ? (
                                            <SVGCaseTimeline
                                                segments={filterGeneralRoutes(segments, mapDetail)}
                                                mapDetail={mapDetail}
                                                caseID={caseID}
                                                size={caseTimelineSize}
                                                key="caseTimelie"
                                                getSvgWrapper={setSVGTimelineRef}
                                            />
                                        )

                                        : null
                                }
                            </Grid>
                        </div>
                        <Box display="flex" justifyContent="center" m={4} p={1} >
                            <DownShareButton variant="contained" color="primary" size="large" startIcon={<ReplyIcon />} onClick={handleClickOpen}>
                                <Box p={1}>
                                    <Text tid="downloadandshare" />
                                </Box>
                            </DownShareButton>
                        </Box>
                        <Dialog fullWidth={true} maxWidth={"lg"} onClose={handleClosetrace} aria-labelledby="customized-dialog-title" open={Open}>
                            <DialogTitle id="customized-dialog-title" onClose={handleClosetrace}>
                                <Box display="flex" flexDirection="row">
                                    <span className="dialogtitle"><Text tid="downloadandshare" /> - <Text tid="travelhistorytimeline" /></span>
                                </Box>

                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-end" : "center"}>
                                            <PNGButton variant="contained" color="primary" size="large" startIcon={<ImageIcon />} onClick={saveSVGTimeline}>
                                                <Box p={1} style={{ width: '10rem' }}><Text tid="btnimage" /></Box>
                                            </PNGButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-start" : "center"}>
                                            <CSVLink data={segments.map(item => item.locationData)} filename={`covid19_myanmar_case_${caseID}_travel_history.csv`}>
                                                <PNGButton variant="contained" color="primary" size="large" startIcon={<DescriptionIcon />} id="csvdownloader" onClick={() => Event("Download Timeline History CSV", `covid19_myanmar_case_${caseID}_travel_history.csv`, "Components/ComponentTimeline")}>
                                                    <Box p={1} style={{ width: '10rem' }}><Text tid="btndata" /></Box>
                                                </PNGButton>
                                            </CSVLink>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={{ height: '2vh' }}>
                                </Box>
                                <Typography variant="h6" gutterBottom>
                                    <Text tid="shareembeddedphrase" />
                                </Typography>
                                <Typography >
                                    <Text tid="copyandpastephrase" />
                                </Typography>
                                <Grid container>
                                    <Grid item md={12} xs={12} style={textareacontainerstyle}>
                                        <TextAreaAutosize
                                            ref={textAreaRefIframe}
                                            id="filled-multiline-static"
                                            rowsMin={3}
                                            cols={20}
                                            value={`<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casetimeline/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}"></iframe>`}
                                            rows="4"
                                            variant="filled"
                                            className="iframearea"
                                        />
                                        <Box display="flex" justifyContent="flex-end" style={modalboxcopystyle}>
                                            <IconButton className="standard-button" onClick={copyToClipboardIframe} style={textareacontainerstyle_iconbutton}>
                                                <FileCopyIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={{ height: '2vh' }}>
                                </Box>
                                <Typography variant="h6" gutterBottom>
                                    <Text tid="sharesocialphrase" />
                                </Typography>
                                <Typography >
                                    <Text tid="copylinkphrase" />
                                </Typography>
                                <Grid container m={1}>
                                    <Grid item xs={12} md={12} style={textareacontainerstyle}>
                                        <Box display="flex" flexDirection="row">
                                            <TextAreaAutosize
                                                ref={textAreaRefLink}
                                                id="filled-multiline-static"
                                                cols={20}
                                                value={`${currenturl}/casetracing/casetimeline/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}`}
                                                className="linkarea"
                                            />
                                            <IconButton className="standard-button" onClick={copyToClipboardLink} style={textareacontainerstyle_iconbutton2}>
                                                <FileCopyIcon />
                                            </IconButton>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </>
                ) :
                    (
                        <>
                        </>
                    )
            }
        </>
    )
}

export default ComponentTimeline