import React from 'react'
import { Router } from 'react-router-dom'
import ReactGA from 'react-ga'
import history from './History'
import Layout from './Layout'

import './styles/style.scss'

ReactGA.initialize('UA-159319358-2')
ReactGA.pageview(window.location.pathname + window.location.search)

history.listen((location) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})



const App = () => {

  return (
    <>
      <Router history={history}>
        <Layout />
      </Router>
    </>
  )
}

export default App