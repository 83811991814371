import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ReactGA from 'react-ga'
import { Emoji} from '../../Elements'
import Icon from '@material-ui/core/Icon'
import logo from './logo.svg'

// import styles from './index.module.scss'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop:"calc(8% + 8rem)",
		bottom: 0
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'row',
		color: "#000",
		padding: '0.2rem',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		textAlign: 'center',
		fontWeight: '600',
		fontSize: '0.95em'
	},
	titleContent: {
		alignSelf: 'center'
	},
	spanContainer: {
		paddingTop: '4rem'
	},
	imageIcon: {
		height: '1.5rem',
		textAlign: 'center',
		marginTop: '1rem'
	},
	iconRoot: {
		textAlign: 'center'
	},
	footerText: {
		fontWeight: 'bold',
		fontSize: '1rem',
	}
}))


export default function PageFooter() {
	ReactGA.initialize('UA-159319358-2')
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar position="static" style={{ background: '#9ABDEB', boxShadow: 'none', color: 'black', textAlign: 'center' }}>
				<Toolbar>
					<Typography color="inherit" className={classes.title} align="center">
						<span className={classes.footerText}>Made with <Emoji symbol="💖" label="Heart" /> by &nbsp;</span>
						<span><Icon classes={{ root: classes.iconRoot }}>
							<ReactGA.OutboundLink eventLabel="Logo" to="https://thibi.co/" target="_self">
								<img className={classes.imageIcon} src={logo} alt="Thibi" />
							</ReactGA.OutboundLink>
						</Icon></span>
					</Typography>
				</Toolbar>
			</AppBar>
		</div>
	);
}
