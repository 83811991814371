import React, { useState, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import Select from 'react-select'
import useDimensions from "react-use-dimensions";
import ReactGA from 'react-ga'
import 'leaflet/dist/leaflet.css'
import { Text } from '../../LangProvider';
import { getFilteredRoute, getRouteSegments, filterGeneralRoutes } from '../../utils'
import SVGCaseTimeline from '../../Components/SVGCaseTimeline/SVGCaseTimeline'
import { DisplayEmptyBox, selectstyle, translateNumber, Event } from '../../Elements'
import styles from './embeddedTimeline.module.scss'

const PageEmbeddedMap = ({ routes, caseid, mapDetail, rows, lang }) => {
    ReactGA.initialize('UA-159319358-2')
    const [caseID, setCaseID] = useState(caseid ? caseid : '01')
    const [selectedValue, setSelectedValue] = useState(caseid)
    const [currentRouteData, setCurrentRouteData] = useState(null)
    const [segments, setSegments] = useState(null)
    // eslint-disable-next-line 
    const [svgTimelineRef, setSVGTimelineRef] = useState(null)
    const [caseTimelineRef, caseTimelineSize] = useDimensions();

    useEffect(() => {
        if (routes && typeof +caseID == 'number') {
            const filteredRoute = getFilteredRoute(routes, caseID);
            setCurrentRouteData(filteredRoute);
        }
    }, [caseID, routes])

    useEffect(() => {
        if (currentRouteData)
            setSegments(getRouteSegments(currentRouteData));
    }, [currentRouteData])

    let options = []
    rows.map(row => {
        let formatted = { value: row.confirmed_case_id, label: row.confirmed_case_id }
        return options.push(formatted)
    })

    const handleSelectChange = e => {
        setCaseID(e.value)
        setSelectedValue(e.value);
        Event("Select Button", `selected case ${e.value} using select Button`, "PageEmbeddedTimeline")
    }


    return (
        <div className={styles.card}>
            {
                caseID && caseID.length ? (
                    <>
                        {
                            segments ?
                                (
                                    <>
                                        {segments.length > 0 ? (
                                            <Grid container>
                                                <Grid item xs={12} className="selectgrid">
                                                    <Box display="flex" justifyContent="center" p={1} bgcolor="background.paper" style={{ borderRadius: '0.2rem' }}>
                                                        <h3> <Text tid="patientcase" /> - {}  </h3>
                                                        <div style={{ width: '8rem' }}><Select
                                                            placeholder={lang === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                                            value={selectedValue}
                                                            options={options}
                                                            onChange={handleSelectChange}
                                                            styles={selectstyle}
                                                        /></div>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} className="embeddedgrid">
                                                    <Grid container ref={caseTimelineRef}>
                                                        <SVGCaseTimeline
                                                            segments={filterGeneralRoutes(segments, mapDetail)}
                                                            mapDetail={mapDetail}
                                                            caseID={caseID}
                                                            size={caseTimelineSize}
                                                            key="caseTimelie"
                                                            getSvgWrapper={setSVGTimelineRef}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        ) : (<>
                                            <DisplayEmptyBox />
                                        </>)}
                                    </>
                                ) :
                                (
                                    <>
                                    </>
                                )
                        }
                    </>
                ) :
                    (
                        <>
                        </>
                    )
            }
        </div>
    )
}

export default PageEmbeddedMap