const km = {
    appname: 'COVID-19 Data for Myanmar',
    appabout: 'About',
    apptitle: 'Travel Histories Of Confirmed Cases',
    appdescription1: 'Click on the eye',
    appdescription2: 'icons in the table to see a map of travel histories',
    patientcase: 'Case',
    patienttravelhistory: 'Travel History',
    btncode: 'Get Embbed Code',
    btnimage: 'Download  as  Image',
    btndata: 'Download Data',
    btntravel: 'Download Travel History',
    appmaptitle: 'Maps',
    apptooltitle: 'Tools',
    btnexplore: ' Explore ',
    mapdetaillabel: 'Location Detail',
    mapdetailspecific: 'Detailed',
    mapdetailgeneral: 'General',
    nodetails: 'Travel history for this case has not been filled in yet. Please check back soon!',
    table: 'Table',
    networkdiagram: 'Network Diagram',
    timeline: 'Timeline',
    map: 'Map',
    casetrackingphrase: 'Our first tool tracks the travel histories of confirmed cases in Myanmar.',
    chooseidphrase: 'Choose the patient ID',
    choosefromtable: 'Choose from table',
    phraserecovered1: 'The patient identified as case number',
    phraserecovered2: 'is a',
    phraserecovered3: 'years old',
    phraserecovered4: 'who has recovered from COVID-19 after receiving treatment at',
    phraseinpatient1: 'The patient identfied as case number',
    phraseinpatient4: 'who is currently receiving inpatient treatment at',
    phrasedeceased1: 'The patient identified as case number',
    phrasedeceased2: 'was a',
    phrasedeceased4: 'who passed away on',
    phrasedeceased5: 'They received treatment for COVID-19 at',
    phraseend: ' .',
    man: 'man',
    woman: 'woman',
    downloadandshare: 'Download & Share',
    travelhistorytimeline: 'Travel History Timeline',
    travelhistorymap: 'Travel History Map',
    and: ' and ',
    shareembeddedphrase: 'Get Embedded Code for your website',
    copyandpastephrase: 'Copy and paste this code on your site where you want the map to appear.',
    sharesocialphrase: 'Share this page on social media',
    copylinkphrase: ' or copy the link to share it.',
    postivecases: 'Positive case patients',
    chartpreviewphrase: 'IWPR Covid Tools Phrase',
    chartpreviewtitle: 'IWPR Covid Tools Title',
    chartpreviewphrasekh: 'Covid-19 Cases in Cambodia',
    chartpreviewtitlekh: 'Cambodia',
    chartpreviewphraseph: 'Covid-19 Cases in the Philippines',
    chartpreviewtitleph: 'The Philippines',
    chartpreviewphrasekhregional: 'Covid-19 Cases in Cambodia',
    chartpreviewtitlekhregional: 'Cambodia Regional Chart'
};

export default km;