import React from "react";
import CaseContainer from "./caseContainer";
import ChartContainer from "./chartContainer";
import CambodiaChart from "./cambodiaChart";
import PhilippineChart from "./philippineChart";
import RegionalCambodiaChart from "./cambodiaRegionalChart";
import { Box } from "@material-ui/core";

const PageHome = () => {
  return (
    <>
      {/* <CaseContainer /> */}
      {/* <Box display="flex" m={4} p={1} style={{ height: '1vh' }}>
                <Box p={1} m={1}>
                    {` `}
                </Box>
            </Box> */}
      <CambodiaChart />
      <Box display="flex" m={2} p={2} style={{ height: "0.5vh" }}>
        <Box p={1} m={1}>
          {` `}
        </Box>
      </Box>
      <PhilippineChart />
      <Box display="flex" m={2} p={2} style={{ height: "0.5vh" }}>
        <Box p={1} m={1}>
          {` `}
        </Box>
      </Box>
      <RegionalCambodiaChart />

      {/* <Box display="flex" m={4} p={1} style={{ height: '2vh' }}>
                <Box p={1} m={1}>
                    {` `}
                </Box>
            </Box> */}
    </>
  );
};

export default PageHome;
