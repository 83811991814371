import React, { useState, useRef, useEffect, useContext } from 'react'
import 'react-leaflet-fullscreen/dist/styles.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import 'leaflet-easyprint';
import { Map, Popup, TileLayer, withLeaflet } from "react-leaflet";
import PrintControlDefault from 'react-leaflet-easyprint';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, Dialog, Typography, Grid, IconButton } from '@material-ui/core'
import TextAreaAutosize from '@material-ui/core/TextareaAutosize'
import { Image as ImageIcon, FileCopy as FileCopyIcon, Reply as ReplyIcon, Description as DescriptionIcon } from '@material-ui/icons'
import { CSVLink } from 'react-csv';
import { useMediaQuery } from 'react-responsive'
import ReactGA from 'react-ga'
import { Text, LanguageContext } from '../../LangProvider';
import { Curve } from 'react-leaflet-curve';
import { filterGeneralRoutes } from '../../utils';
import Marker from 'react-leaflet-enhanced-marker';
import 'leaflet/dist/leaflet.css';
import { DownShareButton, PNGButton, CustomMarkerComponent, DialogTitle, DialogContent, currenturl, modalboxcopystyle, translateNumber, textareacontainerstyle, textareacontainerstyle_iconbutton, textareacontainerstyle_iconbutton2, Event } from '../../Elements'

const useStyles = makeStyles({
    cardMap: {
        width: '98.99%',
        height: '68vh',
        background: '#F3F3F3',
        textAlign: 'center'
    }
})

const CurveWithLeaflet = withLeaflet(Curve)
const PrintControl = withLeaflet(PrintControlDefault)

const ComponentMap = ({ segments, caseID, mapDetail, lang }) => {
    ReactGA.initialize('UA-159319358-2')
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        center: [16.920552, 96.156532],
        zoom: 5
    })
    const [source, setSource] = useState('https://www.mohs.gov.mm/Main/content/page/covid-19-contact')

    useEffect(() => {
        segments && segments.map((segment) => {
            if (segment.locationData.specific_latitude !== '' && segment.locationData.specific_longitude !== '') {
                return setState({ center: [segment.locationData.specific_latitude, segment.locationData.specific_longitude], zoom: 11 })
            }

            if (segment.locationData.source !== '') {
                return setSource(segment.locationData.source)
            }
            return null
        })
    }, [caseID, segments, mapDetail])


    let printControl = useRef(null)
    // eslint-disable-next-line
    let mapRef = useRef(null)
    const classes = useStyles()
    const languageContext = useContext(LanguageContext)
    const textAreaRefIframe = useRef(null)
    const textAreaRefLink = useRef(null)
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 480 })

    function copyToClipboardIframe(e) {
        textAreaRefIframe.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Iframe Link Button to Embedded page", `${currenturl}/casetracing/casemap/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}"></iframe>`, "Components/ComponentMap")
    };

    function copyToClipboardLink(e) {
        textAreaRefLink.current.select()
        document.execCommand('copy')
        e.target.focus()
        Event("Copy Link Button to Embedded page", `${currenturl}/casetracing/casemap/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}`, "Components/ComponentMap")
    };

    const downloadPNG = () => {
        printControl.printMap('A4Portrait', 'Route_Map_Case_' + caseID)
        Event("Download Image as PNG", `Downloaded Route_Map_Case_${caseID}.png`, "Components/ComponentMap")
    }

    const handleClickOpen = () => {
        setOpen(true)
        Event("Download Share Button", `Click download & share button to open modal box for Map Component`, "Components/ComponentMap")
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Card className={classes.cardMap} id="getmapinbox">
                <Map center={state.center} zoom={state.zoom} ref={(ref) => mapRef = ref}>
                    <TileLayer
                        attribution={`
                        <a href=${source}> MOHS source </a>
                       &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`}
                        url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                    />

                    {
                        filterGeneralRoutes(segments, mapDetail).map((d, i) => {
                            // console.log(d[mapDetail].locationEN);
                            return <CurveWithLeaflet positions={d[mapDetail].curvePath} option={{ color: '#c4c4c4' }} key={"route" + i} />
                            // return (RLCurve as any).withLeaflet(<Curve positions={d.curvePath} color='red' key={"route"+i}/>)
                        })
                    }
                    {
                        filterGeneralRoutes(segments, mapDetail).map((d, i) =>
                            // <CircleMarker center={d[mapDetail].from} color="red" radius={10} fill={true} fillColor="lime" fillOpacity={1.0} key={"locationMarker" + i}>
                            // <Popup>
                            //   <strong>Location {i + 1}:</strong><br /> {d[mapDetail].locationEN} 
                            // </Popup>
                            //  <Tooltip permanent><strong>{i + 1}</strong></Tooltip> 
                            <Marker icon={<CustomMarkerComponent text={i + 1} />} position={d[mapDetail].from} key={"marker" + i}>
                                <Popup >
                                    <strong>{lang === 'en' ? `Location ${i + 1} :` : `တည်နေရာ ${translateNumber(i + 1)} :`}</strong><br /> {lang === 'en' ? d[mapDetail].locationEN : d[mapDetail].locationMM}
                                </Popup>
                            </Marker>
                            // </CircleMarker>
                        )}

                    <FullscreenControl position="topright" />
                    <PrintControl ref={(ref) => { printControl = ref; }} position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={true} exportOnly />
                    {/* <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly /> */}
                </Map>
                {/* <RoutesMapLeaflet routes={routes} caseID={caseID} /> */}
            </Card>

            <Box display="flex" justifyContent="center">
                <Box display="flex" justifyContent="center" m={4} p={1} >
                    <DownShareButton variant="contained" color="primary" size="large" startIcon={<ReplyIcon />} onClick={handleClickOpen}>
                        <Box p={1}>
                            <Text tid="downloadandshare" />
                        </Box>
                    </DownShareButton>
                </Box>
                <Box>
                    <Dialog fullWidth={true} maxWidth={"lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                            <Box display="flex" flexDirection="row">
                                <span className="dialogtitle"><Text tid="downloadandshare" /> - <Text tid="travelhistorymap" /></span>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-end" : "center"}>
                                        <PNGButton variant="contained" color="primary" size="large" startIcon={<ImageIcon />} onClick={downloadPNG}>
                                            <Box p={1} style={{ width: '10rem' }}><Text tid="btnimage" /></Box>
                                        </PNGButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box display="flex" justifyContent={isDesktopOrLaptop ? "flex-start" : "center"}>
                                        <CSVLink data={segments.map(item => item.locationData)} filename={`covid19_myanmar_case_${caseID}_travel_history.csv`}>
                                            <PNGButton variant="contained" color="primary" size="large" startIcon={<DescriptionIcon />} id="csvdownloader" onClick={() => Event("Download Map History CSV", `covid19_myanmar_case_${caseID}_travel_history.csv`, "Components/ComponentMap")}>
                                                <Box p={1} style={{ width: '10rem' }}><Text tid="btndata" /></Box>
                                            </PNGButton>
                                        </CSVLink>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box style={{ height: '2vh' }}>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                <Text tid="shareembeddedphrase" />
                            </Typography>
                            <Typography >
                                <Text tid="copyandpastephrase" />
                            </Typography>
                            <Grid container>
                                <Grid item md={12} xs={12} style={textareacontainerstyle}>
                                    <TextAreaAutosize
                                        ref={textAreaRefIframe}
                                        id="filled-multiline-static"
                                        rowsMin={3}
                                        cols={20}
                                        value={`<iframe id="inlineFrameExample" title="Inline Frame Example" width="600" height="200" src="${currenturl}/casetracing/casemap/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}"></iframe>`}
                                        rows="4"
                                        variant="filled"
                                        className="iframearea"
                                    />
                                    <Box display="flex" justifyContent="flex-end" style={modalboxcopystyle}>
                                        <IconButton className="standard-button" onClick={copyToClipboardIframe} style={textareacontainerstyle_iconbutton}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box style={{ height: '2vh' }}>
                            </Box>
                            <Typography variant="h6" gutterBottom>
                                <Text tid="sharesocialphrase" />
                            </Typography>
                            <Typography >
                                <Text tid="copylinkphrase" />
                            </Typography>
                            <Grid container m={1}>
                                <Grid item xs={12} md={12} style={textareacontainerstyle}>
                                    <Box display="flex" flexDirection="row">
                                        <TextAreaAutosize
                                            ref={textAreaRefLink}
                                            id="filled-multiline-static"
                                            rowsMin={3}
                                            cols={20}
                                            value={`${currenturl}/casetracing/casemap/?lang=${languageContext.language.id}&caseID=${caseID}&locationDetail=${mapDetail}`}
                                            className="linkarea"
                                        />
                                        <IconButton className="standard-button" onClick={copyToClipboardLink} style={textareacontainerstyle_iconbutton2}>
                                            <FileCopyIcon />
                                        </IconButton>

                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
        </>)
}

export default ComponentMap