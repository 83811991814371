export default (currentRouteData) => currentRouteData.map((currentRow, i, dataSet) => {
//     confirmed_case: "1"
// location: "Washington, D.C."
// location_id: "91"
// timestamp: "3/13/2020"
// description: "အဆိုပါလူနာသည် ၎င်း၏မိခင်နှင့်အတူ အမေရိကန်နိုင်ငံ၊ ဝါရှင်တန်မြို့မှ (၁၃-၃-၂၀၂၀) ရက်နေ့မှ American Airways (AA 3269) ဖြင့် Chicago သို့လည်းကောင်း၊ Chicago မှ Doha သို့ Qatar Airways (Qatar 726) ဖြင့်လည်းကောင်းထွက်ခွာခဲ့ပါသည်။"
// source: "https://www.mohs.gov.mm/Main/content/page/covid-19-contact"
// location_name_en: "Washington, D.C."
// location_name_mm: "ဝါရှင်တန် ဒီစီ မြို့"
// Location_name_verified: "TRUE"
// specific_latitude: "38.9072"
// specific_longitude: "-77.0369"
// location_verified: "TRUE"
// used_in_location_history: "TRUE"
// general_location: "Washington, D.C."
// general_latitude: "38.9072"
// general_longitude: "-77.0369"
// state_region: "Washington, D.C."
	// console.log(currentRow);

	const getSpecificOrDetailedData = (locationENVar,locationMMVar,latVar,LngVar,currentRow,dataSet,i) => {
		const current = { 
			locationEN: currentRow[locationENVar], 
			locationMM: currentRow[locationMMVar],
			coordinates: [+currentRow[LngVar],+currentRow[latVar]]
		};
		const next = i < dataSet.length - 1 
			? { 
				locationEN: dataSet[i+1][locationENVar], 
				locationMM: dataSet[i+1][locationMMVar],
				coordinates: [+dataSet[i+1][LngVar],+dataSet[i+1][latVar]]
			}
			: current;
		return [
			current,
			next
		]
	}

	const calcRouteData = (d) => {

		const [current,next] = d;
		const from = [current.coordinates[1], current.coordinates[0]];
		const to = [next.coordinates[1], next.coordinates[0]];
		const {locationEN, locationMM} = current;
	
		const offsetX = to[1] - from[1],
			offsetY = to[0] - from[0];
	
		const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
			theta = Math.atan2(offsetY, offsetX);
	
		const thetaOffset = (3.14 / 10);
	
		const r2 = (r / 2) / (Math.cos(thetaOffset)),
			theta2 = theta + thetaOffset;
	
		const midpointX = (r2 * Math.cos(theta2)) + from[1],
			midpointY = (r2 * Math.sin(theta2)) + from[0];
	
		const midpointLatLng = [midpointY, midpointX];
	
		const curvePath = [
			'M', from,
			'Q', midpointLatLng,
			to
		]
		
		return {
			from,
			to,
			midpoint: midpointLatLng,
			curvePath,
			locationEN,
			locationMM
		}

	}

	return {
		specificPath: calcRouteData(getSpecificOrDetailedData("location_name_en","location_name_mm","specific_latitude","specific_longitude",currentRow,dataSet,i)),
		generalPath: calcRouteData(getSpecificOrDetailedData("general_location","general_location","general_latitude","general_longitude",currentRow,dataSet,i)),
		locationData: currentRow
	}
})