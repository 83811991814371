import React from 'react';
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, Typography, IconButton, Box, Grid, Button, Card, Fab } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons';
import { Text } from '../LangProvider';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    DialogTitle: {
        background: '#80F9B7',
        color: '#000000',
        fontWeight: 'bold'
    }
})


export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
})

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)


export const SIMPLEButton = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#F3F3F3',
        marginLeft: '0.2rem',
        '&:hover': {
            fontWeight: 'bolder',
            background: 'linearGradient(-30deg, rgba(154, 189, 231, 1) 0%, rgba(128, 249, 183, 1) 100%)',
            transition: '1.2s',
        },
        fontWeight: '600',
        textTransform: 'none',
        height: '100%',
    },
}))(Button)


export const WHITEButton = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#ddd',
        },
        fontWeight: '600',
        textTransform: 'none',
        margin: '0.4rem',
        width: '100%',
        height: '3.5rem'
    },
}))(Button)

export const WhFab = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#ddd',
        },
    },
}))(Fab)


export const SVGButton = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#80F9B7',
        '&:hover': {
            backgroundColor: '#71D9A1',
        },
        fontWeight: '600',
        textTransform: 'none',
        height: '100%'
    },
}))(Button)


export const PNGButton = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#9ABDEB',
        '&:hover': {
            backgroundColor: '#447BC3',
        },
        fontWeight: '600',
        textTransform: 'none',
        height: '100%',
        width: '15rem !important'
    },
}))(Button)

export const DownShareButton = withStyles(() => ({
    root: {
        color: '#000',
        backgroundColor: '#9ABDEB',
        '&:hover': {
            backgroundColor: '#447BC3',
        },
        fontWeight: '600',
        textTransform: 'none',
        height: '100%',
        width: '18rem !important'
    },
}))(Button)

export const CustomMarkerComponent = (props) => {
    const markerStyle = {
        backgroundColor: elementBackgroundColor,
        color: "white",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        border: "red",
        width: "20px",
        height: "20px",
        borderRadius: "10px",
        position: "absolute",
        left: "40px",
        top: "15px",
        alignItems: "center"
    };
    return <div style={markerStyle}><p style={{ fontSize: "0.9em" }}>{props.text}</p></div>;
}


export const NavLink = styled(Link)`
    color: black;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


export const HelmetElement = ({ name, content }) => {
    return (
        <Helmet
            title={name}
            meta={[
                { name, content }
            ]}
            link={[
                {
                    "rel": "icon",
                    "type": "image/png",
                    "href": "/img/favicon.png"
                }
            ]}
        />
    )
}

export let currenturl = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    currenturl = 'http://localhost:3000'
} else {
    currenturl = 'https://covid19mm.thibi.co'
}

export const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </span>
);


export const elementBackgroundColor = 'rgb(113, 217, 161)'
export const lineGrey = '#c4c4c4'

export const modalboxcopystyle = {
    textAlign: 'center'
}

export function DisplayResources() {
    return (
        <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12}>
                <p> Data sourced from <a href="https://mohs.gov.mm/Main/content/publication/2019-ncov" target="_blank" rel="noopener noreferrer" >Ministry of Health and Sports</a> with additional data cleaning from Ko Nyein Chan Ko Ko and Ko Set Khaing Oo.</p>
                <p> This work and its contents are licensed under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer" >Creative Commons Attribution 4.0 International License</a>. </p>
                <p> Other works (software, source code, etc.) referenced in this website are under their own respective licenses.</p>
                <p> Covid19mm Source Code at  <a href="https://gitlab.com/thibi/covid19mm" target="_blank" rel="noopener noreferrer" > Gitlab</a>.</p>
            </Grid>
        </Grid>
    )
}

export const DisplayEmptyBox = () => {
    return (
        <Card style={{ background: '#F3F3F3', textAlign: 'center', margin: '1.5rem 0rem 1rem 0rem', padding: '0.5rem' }}>
            <Box display="flex" justifyContent="center">
                <Box p={1} style={{ textAlign: 'center' }} >
                    <h3> <Text tid="nodetails" /> </h3>
                </Box>
            </Box>
        </Card>
    )
}


export const DisplayVspace = () => {
    return (
        <Box display="flex" m={4} p={1} style={{ height: '9vh' }}>
            <Box p={1} m={1}>
                {` `}
            </Box>
        </Box>
    )
}

export const dateValueFormatter = (val) => {
    return new Date(val)
}

export const translateNumber = (number) => {
    let digits = number.toString().split('');
    digits = digits.map(d => {
        switch (d) {
            case '0':
                return '၀';
            case '1':
                return '၁';
            case '2':
                return '၂';
            case '3':
                return '၃';
            case '4':
                return '၄';
            case '5':
                return '၅';
            case '6':
                return '၆';
            case '7':
                return '၇';
            case '8':
                return '၈';
            case '9':
                return '၉';
            default:
                return d;
        }
    })
    digits = digits.join('')
    // console.log(digits);
    return digits;
}

export const selectstyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? 0 : 0,
        fontFamily: 'Noto Serif',
        padding: '0.25rem',
        fontSize: '1.1em',
        color: '#000',
        background: 'transparent !important',
        placeholder: {
            color: '#000'
        },
        width: '100%',
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
            border: state.isFocused ? 0 : 0
        }
    })
};

export const textareacontainerstyle = { border: '0.1rem solid #CDCDCD', background: '#F6F6F6' }
export const textareacontainerstyle_iconbutton = { border: '0.1rem solid #CDCDCD', borderBottom: '0px', borderRight: '0px' }
export const textareacontainerstyle_iconbutton2 = { border: '0.1rem solid #CDCDCD', borderBottom: '0px', borderRight: '0px', borderTop: '0px' }
export const formatNum = (val) => {
    return (val < 10) ? '0' + val.toString() : val.toString();
}

export const PageView = () => {
    ReactGA.pageview(window.location.pathname +
        window.location.search);
}

export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};