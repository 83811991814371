export default (segments,mapDetail) => {
  // console.log(segments);

  if (mapDetail === 'specificPath') {
    return segments;
  }
  else {
    return segments.filter((segment, index, arr) => {
      // console.log(segment[mapDetail].locationEN,segment[mapDetail].from);

      if (index === 0 || index === arr.length - 1) return true;

      // if (segment[mapDetail].locationEN === array[index-1].locationEN 
      //   && segment[mapDetail].from.toString() === array[index-1].from.toString()) {
      //     return false;
      //   }
      // else return true;

      if (segment[mapDetail].from[0] === segment[mapDetail].to[0] 
        && segment[mapDetail].from[1] === segment[mapDetail].to[1]) return false;
      else return true;
    })
  }
}