import React, { useState, useEffect, useRef } from 'react';
import { makeNetworkData } from '../../utils'
import { cluster, hierarchy } from 'd3-hierarchy'
import { csvParse } from 'd3-dsv'
import * as d3 from 'd3';
import { DateTime } from "luxon";

// import { path } from 'd3-path'
// import { elementBackgroundColor, lineGrey } from '../../Elements'



const DailyCasesChart = (props) => {
  const { highlight, size, color, numDays } = props;
  const { width } = size;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const svgRef = useRef();


  // const sortedData = data
  //   .slice()
  //   .filter(d => ((d.value !== "") && !isNaN(d.value)))
  //   .sort((a, b) => d3.descending(a.value, b.value))
  // const highlightIndices = highlight.map(h => h.index);

  const leftTextWidth = 10;
  const rightTextWidth = 10;
  const lineHeight = size.height;
  const barPadding = 1;
  const leftAxisPadding = 50;
  const bottomAxisPadding = 20;
  const adjustedWidth = size.width - (leftTextWidth + rightTextWidth + leftAxisPadding);
  const containerWidth = size.width - (leftTextWidth + rightTextWidth);
  const adjustedHeight = lineHeight - (leftTextWidth + rightTextWidth + bottomAxisPadding);
  const containerHeight = lineHeight;

  useEffect(() => {
    // d3.csv("https://docs.google.com/spreadsheets/d/e/2PACX-1vTeklVLDlAUqSi8zwg9XqFTIljHUFJkfcGygwe8WnwG32Hb4h6OmpVZBwOzLubA3hbiSx1sYXTPP_i7/pub?gid=707339072&single=true&output=csv").then((d) => {
    // d3.csv("https://data.opendevelopmentcambodia.net/en/datastore/dump/a3fdfb1b-4d6e-4653-a499-0acfe972a0a5?bom=True").then((d) => {
    d3.csv("https://docs.google.com/spreadsheets/d/e/2PACX-1vRz1nXPXDkm6QMyG0ItvDJ3LIEIu6mScq-Tc4x07MjtaPuqseb8AymD9EPfy0kE6Z2cobUxsDwqJ6iK/pub?gid=0&single=true&output=csv").then((d) => {
      setData(d);
      setLoading(false);
    });
    return () => undefined;
  }, []);


  console.log(data);


  const convertToDate = (dateString) => {
    const dateArray = dateString.split(" ")[0];
    const testdate = new Date(dateString).toISOString().slice(0, 10);
    // console.log("testdate", testdate)
    // console.log(DateTime.fromISO(testdate))
    return DateTime.fromISO(testdate); 
  }

  const daysDiff = (date1,date2) => {
    // const difference = date1.getTime() - date2.getTime();
    // return Math.abs( Math.ceil(difference / (1000 * 3600 * 24)) );
    return date2.diff(date1, ['days']).as('days') ;
  }

  const maxDate = data.length > 0 ? convertToDate(data[data.length - 1]['daterepconf']) : DateTime.now();

  // const dateFormat = d3.timeParse("%d/%m/%Y");
  const nestedData = d3.nest()
    .key(function(d) {
      return d["daterepconf"]; 
    })
    // .key(function(d) { return d["region"]; })
    .rollup(function(leaves) { 
      const leafDate = convertToDate(leaves[0]['daterepconf'])
      return {
        "date": leafDate.toJSDate(),
        "luxonDate": leafDate,
        "datePretty": leafDate.toISODate(),
        "daysFromMax": daysDiff(leafDate,maxDate),
        "totalCases": +d3.sum(leaves, function(d) {return parseInt(d["Record Count"]);})
      } 
    })
    .entries(data);

  console.log("Philippine nestedData", nestedData);


  const dateFill = (dataToFill) => {
    const placeHolderArray = [...Array(numDays).keys()].map(day => {
      const todaysDate = maxDate.plus({ days: -day })
      return {
        "date": todaysDate.toJSDate(),
        "luxonDate": todaysDate,
        "datePretty": todaysDate.toISODate(),
        "daysFromMax": day,
        "totalCases": 0
      }
    })

    return placeHolderArray.map(day => {

      const realDataForDayArray = dataToFill && dataToFill.length > 0
        ? dataToFill.filter(realDataDay => {
            return realDataDay.value.daysFromMax === day.daysFromMax;
          })
        : [];


      if (realDataForDayArray.length > 0) {
        return {
          ...day,
          ...realDataForDayArray[0].value 
        }
      } else {
        return day
      }
      
    })
  }

  const latestNestedData = dateFill(nestedData.filter(row => row.value.daysFromMax <= numDays));


  console.log("Philippine latest", latestNestedData);

  // const [hoverCountry, setHoverCountry] = useState({item: null, isToolTip: false});

  // const useMousePosition = () => {
  //   const [mousePosition, setMousePosition] = useState({ mouseX: null, mouseY: null });
  
  //   const updateMousePosition = ev => {
  //     setMousePosition({ mouseX: ev.clientX, mouseY: ev.clientY });
  //   };
  
  //   useEffect(() => {
  //     window.addEventListener("mousemove", updateMousePosition);
  
  //     return () => window.removeEventListener("mousemove", updateMousePosition);
  //   }, []);
  
  //   return mousePosition;
  // };

  // const { mouseX, mouseY } = useMousePosition();

  const onMouseEnter= (item) => {
    // setTooltip({item:item});
    setHoverCountry({item: item, isToolTip: true}) 
  }
  const onMouseLeave= (item) => {
    // setTooltip(false);
    setHoverCountry({item: item, isToolTip: false}) 
  } 

  const Tooltip = ({country}) => {
    // console.log(mouseX,mouseY);
    // const dataToMap = data[country.properties.ISO_A3];
    const tooltipWidth = 100;
    const tooltipHeight = tooltipWidth;
    // console.log(mouseX,mouseY,size.x,size.y);
    // console.log(country)

    return <foreignObject 
      style={{
        pointerEvents: 'none',
      }}
      x={ mouseX + tooltipWidth < size.x + width - tooltipWidth
        ? mouseX + 20 - size.x
        : mouseX - 10 - size.x - tooltipWidth
      } 
      // y={ mouseY + 10 + 0.6*tooltipHeight < size.y + height
      //   ? mouseY - size.y
      //   : mouseY - size.y - (0.9*tooltipHeight)
      // }
      y={0} 
      width={tooltipWidth} 
      height={tooltipHeight}>
      <div className='tooltip' style={
        {
          backgroundColor: 'rgb(80,128,94)', 
          border: '1px solid', 
          padding: '5px 5px 5px 5px', 
          textAlign: 'left', 
          borderColor: '#fff'}
        }>
        { 
          country.item && country.item.index
          ? <div> 
              {country.item.name}<br/>
              Rank: #{country.item.rank}
            </div>
          : null
        }
        
        
      </div>
    </foreignObject>
  };


  const max = d3.max(latestNestedData.map(d => d.totalCases))

  const formatXTick = (d) => {
    console.log(d)
    return DateTime.fromJSDate(d).toLocaleString({ month: 'long', day: 'numeric' })
  }

  const scaleX = d3.scaleTime().domain(d3.extent(latestNestedData.map(d => d.date))).range([0, adjustedWidth]);
  const scaleY = d3.scaleLinear().domain([0, max]).range([adjustedHeight,0]);
  const yAxis = d3.axisLeft()
    .ticks(5)
    .scale(scaleY)
    .tickSize(-adjustedWidth);
  const xAxis = d3.axisBottom().scale(scaleX).ticks(4).tickFormat(formatXTick);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg
      .select(".y-axis")
      .style("transform", "translateX("+leftAxisPadding+"px)")
      .call(yAxis);
    svg
      .select(".x-axis")
      .style("transform", "translateY("+(adjustedHeight)+"px) translateX("+leftAxisPadding+"px)")
      .call(xAxis);
    svg
      .select(".lineMarkerG")
      .style("transform", "translateX("+leftAxisPadding+"px)")

    svg.selectAll(".y-axis .tick line")
      .attr("stroke-opacity", 0.5)
      .attr("stroke-dasharray", "2,2")
  })

  return (
    <div style={{display:"flex"}}>
      <div style={{paddingLeft: (leftTextWidth)+'px', paddingRight: (rightTextWidth)+'px'}}>
        <svg className="lineMarker" width={containerWidth} height={lineHeight}  ref={svgRef}>
          <g className="y-axis" />
          <g className="x-axis" />
          <g className="lineMarkerG" height={lineHeight} >
            {
              latestNestedData.map((m,i) => {
                return <rect
                  height={adjustedHeight-scaleY(+m.totalCases)} 
                  width={adjustedWidth/latestNestedData.length - barPadding} 
                  x={scaleX(m.date)} 
                  y={scaleY(+m.totalCases)}
                  d={m.totalCases}
                  // fill={
                  //   highlightIndices.includes(m.index) || (hoverCountry.item && hoverCountry.item.index === m.index  && hoverCountry.isToolTip)
                  //   ? color.highlight : color.primary
                  // }
                  fill={color.primary}
                  // onMouseEnter={() => onMouseEnter(m)}
                  // onMouseLeave={() => onMouseLeave(m)}
                />
              })  
            }

            {/* {(hoverCountry.isToolTip &&
              <Tooltip
                x={mouseX}
                y={mouseY}
                country={hoverCountry}
              />
            )} */}
          </g>
        </svg>
      </div>
    </div>
    
  )

}

export default DailyCasesChart

// export default (props) => {
//   const { allData, size, currentID, getSvgWrapper } = props;
//   let correctedSize = (size.width || size.height) ? size : { width: 400, height: 400 };
//   const width = correctedSize.width > 700 ? 700 : (correctedSize.width < 500 ? 500 : correctedSize.width);
//   const height = correctedSize.height > 700 ? 700 : (correctedSize.height < 500 ? 500 : correctedSize.height);
//   const margin = { left: 20, right: 20, top: 20, bottom: 20 }
//   const radius = 0.5 * (width > height ? (height - margin.top - margin.bottom) : (width - margin.left - margin.right));

//   // const angleCorrection = 2 * Math.PI / allData.length;
//   const angleCorrection = 0;
//   const nodeRadius = width / 200;
//   const nodeFontSize = width / 1000;

//   const tree = cluster().size([2 * Math.PI, radius - 100]);
//   const networkData = makeNetworkData(allData);

//   const root = tree(hierarchy(networkData.tree))

//   const [hoverItem, setHoverItem] = useState({ item: null, isHighlight: false });


//   const handleMouseEnter = (item) => {
//     // console.log(item.data);
//     setHoverItem({ item: +item.data.confirmed_case_id, isHighlight: true })
//   }

//   const handleMouseLeave = (item) => {
//     // console.log(item);
//     setHoverItem({ item: null, isHighlight: false })

//   }

//   useEffect(() => {
//     if (currentID) {
//       setHoverItem({ item: +currentID, isHighlight: true })
//     }
//   }, [currentID])

//   return (
//     <div className={'NetworkDiagramWrapper'}>
//       <svg style={{ width: width, height: height, background: '#ffffff' }} ref={ref => getSvgWrapper(ref)} >
//         <g transform={"translate(" + (width / 2) + "," + (height / 2) + ") rotate(-90)"} >
//           {
//             networkData && networkData.edges && root && root.children
//               ? networkData.edges.map((edge, key) => {
//                 return <path
//                   key={"path" + edge.source + "To" + edge.target}
//                   d={getPathForEdge(edge, root.children)}
//                   fill='none'
//                   stroke={
//                     (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
//                       ? elementBackgroundColor
//                       : lineGrey
//                   }
//                   strokeWidth={
//                     (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
//                       ? 3
//                       : 1
//                   }
//                   strokeOpacity={
//                     (hoverItem.item === edge.source || hoverItem.item === edge.target) && hoverItem.isHighlight
//                       ? 1
//                       : 0.3
//                   }
//                 />
//               })
//               : null
//           }
//         </g>
//         <g transform={"translate(" + (width / 2) + "," + (height / 2) + ")"} >
//           {
//             root && root.children
//               ? root.children.map((node, key) => {
//                 return <g transform={getNodeCircleLayoutTransform(node)} key={'nodeWrapper' + key}>
//                   <circle
//                     key={"networkCircle" + key}
//                     r={nodeRadius}
//                     fill={elementBackgroundColor}
//                     onMouseEnter={() => handleMouseEnter(node)}
//                     onMouseLeave={() => handleMouseLeave(node)}
//                   />
//                   <text
//                     key={"netWorkLabel" + key}
//                     dy="0.3em"
//                     dx={(node => node.x > Math.PI ? "-1em" : "1em")(node)}
//                     transform={"rotate(" + (node => node.x > Math.PI ? 180 : 0)(node) + ")"}
//                     style={{
//                       textAnchor: (node => node.x > Math.PI ? "end" : "start")(node),
//                       fill: "#000000",
//                       fontWeight: "bold",
//                       fontSize: nodeFontSize + "em"
//                     }}>
//                     {+node.data.confirmed_case_id}
//                   </text>
//                 </g>
//               })
//               : null
//           }
//         </g>


//       </svg>
//     </div>
//   )
// }
