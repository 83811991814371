import React, { useState, useEffect } from 'react'
import 'react-leaflet-fullscreen/dist/styles.css'
import FullscreenControl from 'react-leaflet-fullscreen'
import 'leaflet-easyprint'
import PrintControlDefault from 'react-leaflet-easyprint'
import { Map, TileLayer, Popup, withLeaflet } from 'react-leaflet'
import { Grid, Box } from '@material-ui/core'
import Select from 'react-select'
import ReactGA from 'react-ga'
import { Text } from '../../LangProvider';
import { translateNumber } from '../../Elements'
import 'leaflet/dist/leaflet.css'
import { getFilteredRoute, getRouteSegments, filterGeneralRoutes } from '../../utils'
import { Curve } from 'react-leaflet-curve'
import Marker from 'react-leaflet-enhanced-marker';
import 'leaflet/dist/leaflet.css';
import { CustomMarkerComponent, DisplayEmptyBox, selectstyle, Event } from '../../Elements'

const CurveWithLeaflet = withLeaflet(Curve)
const PrintControl = withLeaflet(PrintControlDefault)
const EmbeddedMap = ({ routes, caseid, mapDetail, rows, lang }) => {
ReactGA.initialize('UA-159319358-2')
const [state, setState] = useState({
        center: [16.920552, 96.156532],
        zoom: 4
    })
const [caseID, setCaseID] = useState(caseid ? caseid : '01')
const [selectedValue, setSelectedValue] = useState(caseid);
const [currentRouteData, setCurrentRouteData] = useState(null)
const [segments, setSegments] = useState(null)
const [source, setSource] = useState('https://www.mohs.gov.mm/Main/content/page/covid-19-contact')

useEffect(() => {
        setState({ center: [16.920552, 96.156532], zoom: 6 })
}, [caseID, segments, mapDetail])

useEffect(() => {
        if (routes && typeof +caseID == 'number') {
            const filteredRoute = getFilteredRoute(routes, caseID);
            setCurrentRouteData(filteredRoute);
        }
}, [caseID, routes])

useEffect(() => {
        if (currentRouteData)
            setSegments(getRouteSegments(currentRouteData));
}, [currentRouteData])

useEffect(() => {
        segments && segments.map((segment) => {
            if (segment.locationData.source !== '') {
                return setSource(segment.locationData.source)
            }
            return null
        })
}, [segments])

let options = []
rows.map(row => {
        let formatted = { value: row.confirmed_case_id, label: row.confirmed_case_id }
        return options.push(formatted)
})

const handleSelectChange = e => {
        setCaseID(e.value)
        setSelectedValue(e.value);
        Event("Select Button", `selected case ${e.value} using select Button`, "PageEmbeddedMap")
}

return (
        <>
            {
                caseID && caseID.length ? (
                    <>
                        {
                            segments ?
                                (
                                    <>
                                        {segments.length > 0 ? (
                                            <Grid container>
                                                <Grid item xs={12} className="selectgrid">
                                                    <Box display="flex" justifyContent="center" p={1} bgcolor="background.paper" style={{ borderRadius: '0.2rem' }}>
                                                        <h3> <Text tid="patientcase" /> - {}  </h3>
                                                        <div style={{ width: '8rem' }}><Select
                                                            placeholder={lang === 'en' ? caseID : translateNumber(parseInt(caseID))}
                                                            value={selectedValue}
                                                            options={options}
                                                            onChange={handleSelectChange}
                                                            styles={selectstyle}
                                                        /></div>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} className="embeddedgrid">
                                                    <Box display="flex" justifyContent="center" id="mainmap">
                                                        <Box p={1} >
                                                            <Map center={state.center} zoom={state.zoom} style={{ height: '85vh', width: '90vw' }}>
                                                                <TileLayer
                                                                    attribution={`
                                                             <a href=${source}> MOHS source </a>
                                                            &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>`}
                                                                    url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                                                                />
                                                                {
                                                                    segments //&& segments.length > 0
                                                                        ? filterGeneralRoutes(segments, mapDetail).map((d, i) => {
                                                                            return <CurveWithLeaflet positions={d[mapDetail].curvePath} option={{ color: '#c4c4c4' }} key={"route" + i} />
                                                                        })
                                                                        : null
                                                                }
                                                                {
                                                                    segments //&& segments.length > 0
                                                                        ? filterGeneralRoutes(segments, mapDetail).map((d, i) =>
                                                                            <Marker icon={<CustomMarkerComponent text={i + 1} />} position={d[mapDetail].from} key={"marker" + i}>
                                                                                <Popup >
                                                                                <strong>{ lang === 'en' ? `Location ${i+1} :` : `တည်နေရာ ${translateNumber(i+1)} :` }</strong><br /> {lang === 'en' ? d[mapDetail].locationEN : d[mapDetail].locationMM}
                                                                                </Popup>
                                                                            </Marker>
                                                                        )
                                                                        : null
                                                                }
                                                                <FullscreenControl position="topright" />
                                                                <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} />
                                                                <PrintControl position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Export as PNG" exportOnly />
                                                            </Map>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ) : (<>
                                            <DisplayEmptyBox />
                                        </>)}
                                    </>
                                ) :
                                (
                                    <>
                                    </>
                                )
                        }
                    </>
                ) :
                    (
                        <>
                        </>
                    )
            }
        </>
    )
}

export default EmbeddedMap